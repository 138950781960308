import {TopBar, DisplayText} from "@shopify/polaris";
import {KeyMajor, EditMajor} from "@shopify/polaris-icons";
import Axios from "axios";
import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useUserUpdate} from "./UserContext";

export default function TopBarComponent(props) {
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

    const toggleIsUserMenuOpen = useCallback(
      () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
      [],
    );

    const handleNavigationToggle = useCallback(() => {
      console.log('toggle navigation visibility');
    }, []);

    let navigate = useNavigate()
    const updateUserInfo = useUserUpdate()
    const userMenuMarkup = (
      <TopBar.UserMenu
          actions={[
              {
                  items: [{content: 'Account Info', icon: EditMajor, onAction() {
                      navigate("/info")
                      }}],
              },
              {
                  items: [{content: 'Logout', icon: KeyMajor, onAction() {
                          async function getUserInfo() {
                              try {
                                  await Axios.post("user/logout", {}, {
                                      baseURL: process.env.REACT_APP_BASE_URL,
                                      withCredentials: true,
                                  })
                                  updateUserInfo(null)
                              } catch (e) {

                              }
                          }
                          getUserInfo().then()
                  }}],
              },
          ]}
          name={props.firstName || "Account"}
          // detail="Jaded Pixel"
          initials={props.firstName ? props.firstName[0] : "?"}
          open={isUserMenuOpen}
          onToggle={toggleIsUserMenuOpen}
      />
    );

    const searchFieldMarkup = (
        <DisplayText><p style={{color: "#016853"}}>Kim'C Market Fulfillment Services</p></DisplayText>
    );

    return (
        <TopBar
            userMenu={userMenuMarkup}
            searchField={searchFieldMarkup}
            onNavigationToggle={handleNavigationToggle}
        />
    );
}