import {Page, IndexTable, EmptyState, TextStyle} from "@shopify/polaris";
import {useState, useEffect} from "react";
import Axios from "axios"

import RevokeInvitationModel from "../RevokeInvitationModel";
import PageWrapper from "../../PageWrapper";

export default function AccountInvitations() {

    const [accountInvitations, setAccountInvitations] = useState([])
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        async function getAccountInvitations() {
            const {data} = await Axios.get("user/invitations", {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setAccountInvitations(data)
        }
        getAccountInvitations().then(() => {
            setPageLoading(false)
        })
    }, [])


    const rowMarkup = accountInvitations.map((invitation, index) => {
        const {id, createdFor, createdBy, acceptedBy, acceptedAt, usertype, createdAt, expires, revokedBy, revokedAt, shopifyGQLId} = invitation
        const createdAtDate = new Date(createdAt)
        const expiresDate = new Date(expires)

        const dayMonthYearCreatedAt = new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric", year: "numeric"}).format(createdAtDate)
        const timeCreatedAt = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(createdAtDate).toLowerCase()
        const dayMonthYearExpiresAt = new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric", year: "numeric"}).format(expiresDate)
        const timeExpiresAt = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(expiresDate).toLowerCase()

        let status
        let statusStyle = ""
        let rowStatus = "subdued"

        if (Date.now() > expiresDate) {
            status = "Expired"
            statusStyle = "warning"
        } else if (acceptedBy) {
            const acceptedDate = new Date(acceptedAt)
            const dayMonthYearAccepted = new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric", year: "numeric"}).format(acceptedDate)
            const timeAccepted = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(acceptedDate).toLowerCase()
            status = `Accepted by ${acceptedBy} on ${dayMonthYearAccepted} at ${timeAccepted}`
            rowStatus = "success"
            statusStyle = "positive"
        } else if (revokedBy) {
            const revokedDate = new Date(revokedAt)
            const dayMonthYearRevoked = new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric", year: "numeric"}).format(revokedDate)
            const timeRevoked = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(revokedDate).toLowerCase()
            status = `Revoked by ${revokedBy} on ${dayMonthYearRevoked} at ${timeRevoked}`
            statusStyle = "negative"
        } else {
            status = "Pending Acceptance"
        }

        return (
            <IndexTable.Row id={id} key={id} position={index} status={rowStatus}>
                <IndexTable.Cell>{createdFor}</IndexTable.Cell>
                <IndexTable.Cell>{usertype === "FULFILLMENT SERVICE" ? "FULFILLMENT MEMBER" : usertype}</IndexTable.Cell>
                <IndexTable.Cell>{dayMonthYearExpiresAt} at {timeExpiresAt}</IndexTable.Cell>
                <IndexTable.Cell><TextStyle variation={statusStyle}>{status}</TextStyle></IndexTable.Cell>
                <IndexTable.Cell>{createdBy} on {dayMonthYearCreatedAt} at {timeCreatedAt}</IndexTable.Cell>
            </IndexTable.Row>
        )
    })

    const emptyStateMarkup =
        <EmptyState
            heading={"No account invitations found"}
            image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
        >
            <p>Any invitations sent will show up here.</p>
        </EmptyState>

    return (
        <PageWrapper currentPage={"Account Invitations"}>
            <Page
                title={"Account Invitations Sent"}
                subtitle={"View detailed information on sent account invitations. You can also revoke pending invitations"}
                fullWidth={true}
                primaryAction={<RevokeInvitationModel />}
            >
                <IndexTable
                    resourceName={{singular: 'fulfillment service', plural: 'fulfillment services'}}
                    itemCount={accountInvitations.length}
                    headings={[
                        {title: "Invitee"},
                        {title: "Account Type"},
                        {title: "Expiration Date"},
                        {title: "Status"},
                        {title: "Sent By"}
                    ]}
                    selectable={false}
                    emptyState={emptyStateMarkup}
                    loading={pageLoading}
                >
                    {rowMarkup}
                </IndexTable>
            </Page>
        </PageWrapper>
    )
}