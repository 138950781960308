import {Page, Loading, Tabs, EmptyState, Card, Stack, TextContainer, Banner, Toast} from "@shopify/polaris";

import {useLocation, useNavigate} from "react-router-dom";

import {returnStatusRequestBadge, returnStatusFulfillmentBadge} from "../../functions/returnStatusBadge"

import Axios from 'axios'
import {useState, useEffect, useCallback} from "react";

import SingleFulfillmentUnfulfilledItems from "./SingleFulfillmentUnfulfilledItems";
import SingleFulfillmentFulfilledItems from "./SingleFulfillmentFulfilledItems";
import VSF_SKELETON from "../../../../../../loading/VSF_SKELETON";
import PageWrapper from "../../../../PageWrapper";
import {useUser} from "../../../../../../UserContext";

export default function ViewSingleFulfillment() {

    const userInfo = useUser()
    const usertype = userInfo.usertype
    const isFulfillmentMember = usertype === "FULFILLMENT SERVICE"

    const location = useLocation()

    const [fulfillmentInfo, setFulfillmentInfo] = useState(location.state ? location.state : null)
    const [existingFulfillments, setExistingFulfillments] = useState([])
    const [loading, setLoading] = useState(true)

    const orderName = fulfillmentInfo.order.name
    const requestStatus = returnStatusRequestBadge(fulfillmentInfo.requestStatus, fulfillmentInfo.status)
    const fulfillmentStatus = returnStatusFulfillmentBadge(fulfillmentInfo.status, fulfillmentInfo.requestStatus)
    const statuses = <Stack alignment={"center"}>{requestStatus}{fulfillmentStatus}</Stack>
    const date = new Date(fulfillmentInfo.order.createdAt)
    const dayMonth = new Intl.DateTimeFormat('en-US', {month: "long", day: "numeric"}).format(date)
    const year = new Intl.DateTimeFormat('en-US', {year: "numeric"}).format(date)
    const time = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(date).toLowerCase()

    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(
        () => setToastActive((toastActive) => !toastActive),
        [],
    );
    const [toastContent, setToastContent] = useState("Success")
    const toastMarkup = toastActive ? (
        <Toast onDismiss={toggleToastActive} content={toastContent} />
    ) : null;

    // For right column
    // const purchaserName = `${fulfillmentInfo.order.customer.firstName} ${fulfillmentInfo.order.customer.lastName}`
    // const purchaserPhoneNumber = `${fulfillmentInfo.order.customer.phone}`

    // Get Any Fulfilled Items For Corresponding Fulfillment Order
    useEffect(() => {
        async function fetchFulfilledOrders() {
            try {
                const {data} = await Axios.get(`fulfillmentOrders/getFulfillmentsByOrder?id=${fulfillmentInfo.id}`,  {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
                return data
            } catch (e) {

            }
        }
        fetchFulfilledOrders().then(res => {
            setExistingFulfillments(res.fulfillmentOrder.fulfillments.edges)
            setLoading(false)
        })
    }, [fulfillmentInfo.id])

    // Tab Logic

    const [selected, setSelected] = useState(0)
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), [])
    const tabs = [
        {
            id: "unfulfilled-items",
            content: "Unfulfilled",
            accessibilityLabel: "Unfulfilled Items",
            panelID: "unfulfilled-items-content"
        },
        {
            id: "fulfilled-items",
            content: "Fulfilled",
            accessibilityLabel: "Fulfilled Items",
            panelID: "fulfilled-items-content"
        }
    ]
    // End Tab Logic
    let navigate = useNavigate()
    return (
        <PageWrapper currentPage={"Fulfillment Orders"}>
            {
                loading ?
                    <div style={{height: '100px'}}>
                        <Loading />
                        <VSF_SKELETON />
                    </div>
                    :
                    <Page
                        breadcrumbs={[{content: 'View Fulfillment Details', onAction() {
                            navigate(-1)
                        }}]}
                        title={orderName}
                        titleMetadata={statuses}
                        subtitle={`${dayMonth}, ${year} at ${time} from Kim'C Market`}
                        fullWidth
                    >
                        <Stack distribution={"center"} vertical={true}>
                            {!isFulfillmentMember &&
                                <Banner status={"info"}>
                                    <TextContainer>
                                        <p>Only assigned fulfillment members to this fulfillment service can make changes here.</p>
                                        <p>You can make changes to this fulfillment order using the Shopify Admin panel via the Orders section</p>
                                    </TextContainer>
                                </Banner>
                            }
                            {fulfillmentInfo.requestStatus === "CANCELLATION_REJECTED" &&
                                <Stack.Item fill>
                                    <Banner status={"info"}>
                                        {isFulfillmentMember ?
                                            <p>You rejected a cancellation request from the merchant on this order.
                                                You can continue fulfilling the order as normal.</p>
                                            :
                                            <p>{location.state.serviceName} rejected a cancellation request from you on this order.
                                                See the Shopify Admin Orders section for more details</p>
                                        }

                                    </Banner>
                                </Stack.Item>
                            }
                            <Stack.Item fill>
                                <Card>
                                    {
                                        fulfillmentInfo.requestStatus === "ACCEPTED" && fulfillmentInfo.status === "CLOSED" ?
                                            <Card title={"All items in this order have been fulfilled. You can still edit tracking information."}>
                                                <SingleFulfillmentFulfilledItems
                                                    fulfillmentInfo={fulfillmentInfo}
                                                    existingFulfillments={existingFulfillments}
                                                    setExistingFulfillments={setExistingFulfillments}
                                                    toggleToastActive={toggleToastActive} setToastContent={setToastContent}
                                                />
                                            </Card>
                                            :
                                            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
                                                {selected === 0 &&
                                                    <SingleFulfillmentUnfulfilledItems
                                                        fulfillmentInfo={fulfillmentInfo}
                                                        setFulfillmentInfo={setFulfillmentInfo}
                                                        setExistingFulfillments={setExistingFulfillments}
                                                        toggleToastActive={toggleToastActive} setToastContent={setToastContent}
                                                    />
                                                    // Empty state for Unfulfilled tab is in above component

                                                }
                                                {selected === 1 && (existingFulfillments.length !== 0 ?
                                                    <SingleFulfillmentFulfilledItems
                                                        fulfillmentInfo={fulfillmentInfo}
                                                        existingFulfillments={existingFulfillments}
                                                        setExistingFulfillments={setExistingFulfillments}
                                                        toggleToastActive={toggleToastActive} setToastContent={setToastContent}
                                                    />
                                                    :
                                                    <EmptyState
                                                        heading={"No items fulfilled"}
                                                        image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
                                                    >
                                                        <p>Fulfilled items automatically move to this tab.</p>
                                                    </EmptyState>
                                                    )
                                                }
                                            </Tabs>
                                    }
                                </Card>
                            </Stack.Item>
                        </Stack>
                        {toastMarkup}
                    </Page>

            }
        </PageWrapper>
    )
}