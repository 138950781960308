import {useState, useEffect, useCallback} from "react";
import {
    Frame,
    Button,
    DisplayText,
    Form,
    FormLayout,
    InlineError,
    Link,
    Stack,
    TextField,
    TextStyle,
    Toast,
    List
} from "@shopify/polaris";
import {useLocation, useNavigate} from "react-router-dom";
import Axios from "axios";
import validator from "validator/es";

export default function RegisterUser() {

    const [invitationInfo, setInvitationInfo] = useState({})
    let navigate = useNavigate()

    let location = useLocation()
    useEffect(() => {
        const path = location.pathname
        const idStart = path.lastIndexOf("/")
        const invitationId = path.substring(idStart + 1)
        async function getInvitationInfo() {
            try {
                const {data} = await Axios.get(`user/register/${invitationId}`, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
                setInvitationInfo(data)
                setEmailFieldValue(data.createdFor)
            } catch (e) {
                setInvitationInfo(null)
            }

        }
        getInvitationInfo().then()
    }, [location.pathname])
    const [accountCreated, setAccountCreated] = useState(false)
    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Account Created"} onDismiss={toggleToastActive} />
    ) : null



    const [emailFieldValue, setEmailFieldValue] = useState("")
    const [passwordFieldValue, setPasswordFieldValue] = useState("")
    const [confirmPasswordFieldValue, setConfirmPasswordFieldValue] = useState("")
    const [firstNameFieldValue, setFirstNameFieldValue] = useState("")
    const [lastNameFieldValue, setLastNameFieldValue] = useState("")
    const [phoneNumberFieldValue, setPhoneNumberFieldValue] = useState("")

    const [emailRequiredMessage, setEmailRequiredMessage] = useState(false)
    const [passwordRequiredMessage, setPasswordRequiredMessage] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)


    const [registerError, setRegisterError] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(false)

    const handleEmailChange = useCallback((value) => {
        setEmailFieldValue(value.trimStart())
        value.length > 0 && setEmailRequiredMessage(false)
    }, [])
    const handlePasswordChange = useCallback((value) => setPasswordFieldValue(value), [])
    const handleConfirmPasswordChange = useCallback((value) => setConfirmPasswordFieldValue(value), [])
    const handleFirstNameChange = useCallback((value) => setFirstNameFieldValue(value.trimStart()), [])
    const handleLastNameChange = useCallback((value) => setLastNameFieldValue(value.trimStart()), [])
    const handlePhoneNumberChange = useCallback((value) => setPhoneNumberFieldValue(value.trimStart()), [])

    const handleLogin = async () => {
        if (emailFieldValue.trimStart().length === 0) {
            setEmailRequiredMessage(true)
            setRegisterError({error: true, message: "Email is required"})
            return
        } else {
            setEmailRequiredMessage(false)
            setRegisterError({error: false, message: ""})
        }

        if (passwordFieldValue.length === 0) {
            setPasswordRequiredMessage(true)
            setRegisterError({error: true, message: "Password is required"})
            return
        } else {
            setPasswordRequiredMessage(false)
            setRegisterError({error: false, message: ""})
        }

        if (passwordFieldValue !== confirmPasswordFieldValue) {
            setConfirmPasswordError(true)
            setRegisterError({error: true, message: "Confirm your password"})
            return
        } else {
            setConfirmPasswordError(false)
            setRegisterError({error: false, message: ""})
        }

        const phoneNumberTrimmed = phoneNumberFieldValue.trimEnd()
        const phoneNumberWithPlus = `+${phoneNumberTrimmed}`
        if (phoneNumberTrimmed.length > 0 && !validator.isMobilePhone(phoneNumberWithPlus, 'any', {strictMode: true})) {
            setRegisterError({error: true, message: `Invalid phone number format. Make sure to include the area code.`})
            setLoading(false)
            return
        }

        setLoading(true)
        try {
            const path = location.pathname
            const idStart = path.lastIndexOf("/")
            const invitationId = path.substring(idStart + 1)
            await Axios.post(`user/register/${invitationId}`, {
                usertype: invitationInfo.usertype.trim(),
                email: emailFieldValue.trimEnd(),
                password: passwordFieldValue,
                firstName: firstNameFieldValue.trimEnd(),
                lastName: lastNameFieldValue.trimEnd(),
                phoneNumber: phoneNumberFieldValue.trimEnd(),
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })

            toggleToastActive()
            setAccountCreated(true)
            setRegisterError({error: false, message: ""})
        } catch (e) {
            if (e.response.data.error.type === "email-exists") {
                setRegisterError({error: true, message: e.response.data.error.message})
            }
            else
                setRegisterError({error: true, message: "An error has occurred on our end. Contact support."})
        }
        setLoading(false)
    }

    const forbiddenMarkup =
        <Stack vertical={true}>
            <DisplayText size={"extraLarge"}>Invalid invitation</DisplayText>
            <TextStyle variation={"warning"}>There can be several reasons for this: </TextStyle>
            <List type={"bullet"}>
                <List.Item>The invitation has already been redeemed</List.Item>
                <List.Item>The invitation link is invalid</List.Item>
                <List.Item>The invitation link has expired</List.Item>
                <List.Item>The invitation link has been canceled</List.Item>
            </List>
        </Stack>
    return (
        <Frame>
            <div className={"loginCard"}>
                {!invitationInfo ? forbiddenMarkup :
                    (
                        !accountCreated ?
                            <>
                                <Stack vertical={true}>
                                    <DisplayText size={"extraLarge"}>Create an Account</DisplayText>
                                    <TextStyle variation={"subdued"}>Kim'C Market Fulfillment Service Portal</TextStyle>
                                </Stack>
                                <br />
                                <Form onSubmit={handleLogin}>
                                    <FormLayout>
                                        <TextField autoFocus={true} label={"Email"} autoComplete={"off"}
                                                   value={emailFieldValue} onChange={handleEmailChange}
                                                   disabled helpText={"This invitation is for the above email only"}
                                                   requiredIndicator={true}
                                                   type={"email"} inputMode={"email"}
                                                   error={(emailRequiredMessage && "Enter your email")}

                                        />
                                        <TextField label={"Password"} autoComplete={"off"}
                                                   value={passwordFieldValue} onChange={handlePasswordChange}
                                                   requiredIndicator={true}
                                                   type={"password"} min={8} minLength={8}
                                                   error={passwordRequiredMessage && "Enter your password"}
                                        />
                                        <TextField label={"Confirm Password"} autoComplete={"off"}
                                                   value={confirmPasswordFieldValue} onChange={handleConfirmPasswordChange}
                                                   requiredIndicator={true}
                                                   type={"password"} min={8} minLength={8}
                                                   error={confirmPasswordError && "Passwords do not match"}
                                        />
                                        <TextField label={"First Name"} autoComplete={"off"}
                                                   value={firstNameFieldValue} onChange={handleFirstNameChange}
                                        />
                                        <TextField label={"Last Name"} autoComplete={"off"}
                                                   value={lastNameFieldValue} onChange={handleLastNameChange}
                                        />
                                        <TextField label={"Phone Number"} autoComplete={"off"}
                                                   value={phoneNumberFieldValue} onChange={handlePhoneNumberChange}
                                                   type={"tel"} inputMode={"tel"}
                                        />
                                        <Button submit={true} loading={loading} fullWidth primary>Register</Button>
                                        {registerError.error && <Stack distribution={"center"}>
                                            <InlineError message={registerError.message} fieldID={"errorCreatingAccount"}/>
                                        </Stack>}
                                    </FormLayout>
                                </Form>
                                <br />
                            </>
                            :
                            <Stack vertical={true}>
                                <DisplayText size={"extraLarge"}>Account created!</DisplayText>
                                <Link onClick={() => navigate("../../")}>Click here to login</Link>
                            </Stack>
                    )
                }
                {toastMarkup}
            </div>
        </Frame>
    )
}