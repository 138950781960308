import PropTypes from 'prop-types'
import {Frame} from "@shopify/polaris";
import TopBarComponent from "../../TopBarComponent";
import {useUser} from "../../UserContext";

import OMNINavigation from "./OMNI/OMNINavigation";
import MONavigation from "./Merchant Owners/MONavigation";
import MANavigation from "./Merchant Admins/MANavigation";

export default function PageWrapper({currentPage, children}) {
    const userInfo = useUser()
    const usertype = userInfo.usertype

    const logo = {
        width: 45,
        topBarSource:"https://cdn.shopify.com/s/files/1/0013/4928/8020/files/Asset_2_x80.png?v=1646766106",
        accessibilityLabel: "Kim'C Market Logo",
    };

    let wrapperMarkup

    switch (usertype) {
        case "OMNI":
            wrapperMarkup =
                <Frame
                    topBar={<TopBarComponent firstName={userInfo && userInfo.firstName} />}
                    navigation={<OMNINavigation currentPage={currentPage} />}
                    logo={logo}
                >
                    {children}
                </Frame>
            break
        case "MERCHANT OWNER":
            wrapperMarkup =
                <Frame
                    topBar={<TopBarComponent firstName={userInfo && userInfo.firstName} />}
                    navigation={<MONavigation currentPage={currentPage} />}
                    logo={logo}
                >
                    {children}
                </Frame>
            break
        case "MERCHANT ADMIN":
            wrapperMarkup =
                <Frame
                    topBar={<TopBarComponent firstName={userInfo && userInfo.firstName} />}
                    navigation={<MANavigation currentPage={currentPage} />}
                    logo={logo}
                >
                    {children}
                </Frame>
            break
        case "FULFILLMENT SERVICE":
            wrapperMarkup =
                <Frame
                    topBar={<TopBarComponent firstName={userInfo && userInfo.firstName} />}
                    logo={logo}
                >
                    {children}
                </Frame>
            break
        default:
            return
    }

    return wrapperMarkup

}

PageWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired
    ]),
    currentPage: PropTypes.string.isRequired,
}