import React, {useContext, useState} from "react";

const UserContext = React.createContext(null)
const UserUpdateContext = React.createContext((data) => console.log("Not initialized"))

export function useUser() {
    return useContext(UserContext)
}

export function useUserUpdate() {
    return useContext(UserUpdateContext)
}

export function UserProvider({ children }) {
    const [userInfo, setUserInfo] = useState(null)

    function updateUserInfo(newInfo) {
        setUserInfo(newInfo)
    }

    return (
        <UserContext.Provider value={userInfo}>
            <UserUpdateContext.Provider value={updateUserInfo}>
                {children}
            </UserUpdateContext.Provider>
        </UserContext.Provider>
    )
}