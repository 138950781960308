import {Button, Modal, Banner, Stack, InlineError, FormLayout, Select, TextField} from "@shopify/polaris";
import {useState, useCallback} from "react";

import Axios from "axios"
import LineItemTrackingModalInfo from "./LineItemTrackingModalInfo";

export default function LineItemTrackingModal(props) {

    const [spinner, setSpinner] = useState(false)
    const [active, setActive] = useState(false);
    const handleChange = useCallback(() => setActive(!active), [active]);
    const activator = props.updateTracking ? <Button onClick={handleChange} loading={spinner} fullWidth={true}>Edit Tracking</Button>
                    : <Button disabled={props.disableModal} onClick={handleChange} loading={spinner} primary fullWidth={true}>Fulfill Item</Button>;

    // Form Fields
    const [quantityToFulfill, setQuantityToFulfill] = useState(props.item.unfulfilledQuantity)
    const handleQuantityToFulfillChange = useCallback((value) => setQuantityToFulfill(value), [])
    const [selected, setSelected] = useState(props.updateTracking ? props.item.trackingInfo[0].company : "Select")
    const [selectedRequiredMessage, setSelectedRequiredMessage] = useState(false)
    const handleSelectChange = useCallback((value) => {
        setSelected(value)
        if (value !== "Select")
            setSelectedRequiredMessage(false)
    }, [])

    const [otherOption, setOtherOption] = useState("")
    const [otherRequiredMessage, setOtherRequiredMessage] = useState(false)
    const handleOtherChange = useCallback((value) => {
        value = value.trimStart()
        setOtherOption(value)
        if (value.length === 0)
            setOtherRequiredMessage(true)
        else
            setOtherRequiredMessage(false)
    }, [])
    const options = [
        {label: "Logen", value: "Logen"},
        {label: "CJ Logistics", value: "CJ Logistics"},
        {label: "Korean Post", value: "Korean Post"},
        {label: "Lotte Global Logistics", value: "Lotte Global Logistics"},
        {label: "Hanjin Express", value: "Hanjin Express"},
        {label: "Other", value: "other"}
    ]

    const [trackingNumber, setTrackingNumber] = useState(props.updateTracking ? props.item.trackingInfo[0].number : "")
    const [trackingNumberRequiredMessage, setTrackingNumberRequiredMessage] = useState(false)
    const handleTrackingNumberChange = useCallback((value) => {
        value = value.trimStart()
        setTrackingNumber(value)
        if (value.length === 0)
            setTrackingNumberRequiredMessage(true)
        else
            setTrackingNumberRequiredMessage(false)
    }, [])
    const [trackingURL, setTrackingURL] = useState(props.updateTracking ? props.item.trackingInfo[0].url : "")
    const [trackingURLRequiredMessage, setTrackingURLRequiredMessage] = useState(false)
    const handleTrackingURLChange = useCallback((value) => {
        value = value.trimStart()
        setTrackingURL(value)
        if (value.length === 0)
            setTrackingURLRequiredMessage(true)
        else
            setTrackingURLRequiredMessage(false)
    }, [])

    const validateFields = () => {
        let error = false
        if (selected === "Select") {
            setSelectedRequiredMessage(true)
            error = true
        }
        if (selected === "Other" && otherOption.length === 0) {
            setOtherRequiredMessage(true)
            error = true
        }
        if (trackingNumber.length === 0) {
            setTrackingNumberRequiredMessage(true)
            error = true
        }
        if (trackingURL.length === 0) {
            setTrackingURLRequiredMessage(true)
            error = true
        }
        return error
    }


    // End Form Fields

    const [errorFulfilling, setErrorFulfilling] = useState(false)
    const handleFulfillSubmit = async () => {
        setSpinner(true)
        try {
            await Axios.post("fulfillmentOrders/fulfill_Item", {
                fulfillment: {
                    lineItemsByFulfillmentOrder: [
                        {
                            fulfillmentOrderId: props.fulfillmentOrderId,
                            fulfillmentOrderLineItems: [
                                {
                                    id: props.item.id,
                                    quantity: quantityToFulfill
                                }
                            ]
                        }
                    ],
                    notifyCustomer: false,
                    trackingInfo: {
                        company: selected === "other" ? otherOption : selected,
                        number: trackingNumber,
                        url: trackingURL
                    }
                }
            },  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            props.setToastContent("Item(s) fulfilled")
            props.toggleToastActive()
            setActive(false)
            await props.updateFulfillmentOrder()
            const {data} = await Axios.get(`fulfillmentOrders/getFulfillmentsByOrder?id=${props.fulfillmentOrderId}`,  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            props.setExistingFulfillments(data.fulfillmentOrder.fulfillments.edges)
        } catch (e) {
            setErrorFulfilling(true)
            console.log(e)
        }
        setSpinner(false)
    }


    const [errorEditingTracking, setErrorEditingTracking] = useState(false)
    const handleUpdateTrackingSubmit = async () => {
        setSpinner(true)
        try {
            await Axios.post(`fulfillmentOrders/updateTracking`, {
                fulfillmentId: props.item.fulfillmentId,
                trackingInfoInput: {
                    company: selected === "other" ? otherOption : selected,
                    number: trackingNumber,
                    url: trackingURL
                }
            },  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            props.setToastContent("Tracking updated")
            props.toggleToastActive()
            setActive(false)
            const {data} = await Axios.get(`fulfillmentOrders/getFulfillmentsByOrder?id=${props.fulfillmentOrderId}`,  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            props.setExistingFulfillments(data.fulfillmentOrder.fulfillments.edges)
        } catch (e) {
            setErrorEditingTracking(true)
        }
        setSpinner(false)
    }


    return (
        <Modal
            activator={activator}
            open={active}
            onClose={handleChange}
            title={props.updateTracking ? `Update Tracking` : `Fulfill ${props.name}`}
            footer={props.updateTracking ?
                (errorEditingTracking &&
                <InlineError message={"An error occurred when trying to edit tracking information"}
                             fieldID={"errorEditingTracking"}
                />)
                :
                (errorFulfilling &&
                <InlineError
                    message={"An error occurred when trying to fulfill this item"}
                    fieldID={"errorFulfillingItem"}
                />)}
        >
            <Modal.Section>
                {
                    props.updateTracking ?
                    <Banner status={"info"}>This will update tracking for all items under Fulfillment {props.item.fulfillmentName}</Banner>
                    :
                    <LineItemTrackingModalInfo item={props.item} quantityToFulfill={quantityToFulfill}
                                            handleQuantityToFulfillChange={handleQuantityToFulfillChange}
                                               maxQuantity={props.item.unfulfilledQuantity} fulfillmentInfo={props.fulfillmentInfo}
                    />
                }
            </Modal.Section>
            <Modal.Section>
                <FormLayout>
                    <Select
                        label={"Shipping Company"} options={options}
                        value={selected} onChange={handleSelectChange}
                        requiredIndicator placeholder={props.updateTracking ? props.item.trackingInfo[0].company : "Select"}
                        error = {selectedRequiredMessage && "Shipping company is required"}
                        onBlur={() => {
                            if (selected === "Select")
                                setSelectedRequiredMessage(true)
                        }}
                    />
                    {selected === "other" &&
                        <TextField
                            label={"Other Shipping Company"} autoComplete={"off"}
                            value={otherOption} onChange={handleOtherChange}
                            requiredIndicator
                            error={otherRequiredMessage && "Other shipping company is required"}
                            onBlur={() => {
                                if (otherOption.length === 0)
                                    setOtherRequiredMessage(true)
                            }}
                        />
                    }
                    <TextField
                        label={"Tracking Number"} autoComplete={"off"}
                        value={trackingNumber} onChange={handleTrackingNumberChange}
                        requiredIndicator
                        error={trackingNumberRequiredMessage && "Tracking number is required"}
                        onBlur={() => {
                            if (trackingNumber.length === 0)
                                setTrackingNumberRequiredMessage(true)
                        }}
                    />
                    <TextField
                        label={"Tracking URL"} autoComplete={"off"}
                        value={trackingURL} onChange={handleTrackingURLChange}
                        requiredIndicator
                        error={trackingURLRequiredMessage && "Tracking URL is required"}
                        onBlur={() => {
                            if (trackingURL.length === 0)
                                setTrackingURLRequiredMessage(true)
                        }}
                    />
                </FormLayout>
            </Modal.Section>
            <Modal.Section>
                <Stack distribution={"trailing"}>
                    {/*{spinner && <Spinner accessibilityLabel="fulfilling items" size="small" />}*/}
                    {props.updateTracking ?
                        <Button
                            primary
                            loading={spinner}
                            onClick={
                                async () => {
                                    setErrorFulfilling(false)
                                    const error = validateFields()
                                    if (!error)
                                        await handleUpdateTrackingSubmit()
                                }
                            }>Update Tracking</Button>
                        :
                        <Button
                            primary
                            loading={spinner}
                            onClick={
                                async () => {
                                    setErrorEditingTracking(false)
                                    const error = validateFields()
                                    if (!error)
                                        await handleFulfillSubmit()
                                }
                            }>Fulfill</Button>
                    }
                </Stack>
            </Modal.Section>
        </Modal>
    )
}