import {Button, Card, Link, ResourceList, Stack, TextContainer, TextStyle} from "@shopify/polaris";
import {returnFulfilledResourceItem} from "../../functions/returnFulfilledResourceItem"
import LineItemTrackingModal from "./modals/LineItemTrackingModal";
import {useEffect} from "react";
import Axios from "axios";
import {useUser} from "../../../../../../UserContext";
import HaeDomChonInfo from "./HaeDamChonInfo";
import GiftToKoreaInfo from "./GiftToKoreaInfo";

export default function SingleFulfillmentFulfilledItems({fulfillmentInfo, existingFulfillments, setExistingFulfillments, toggleToastActive, setToastContent}) {

    const userInfo = useUser()
    const usertype = userInfo.usertype
    const isFulfillmentMember = usertype === "FULFILLMENT SERVICE"

    useEffect(() => {
        (async function updateFulfillments() {
            const {data} = await Axios.get(`fulfillmentOrders/getFulfillmentsByOrder?id=${fulfillmentInfo.id}`,  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setExistingFulfillments(data.fulfillmentOrder.fulfillments.edges)
        })();
    }, [fulfillmentInfo, setExistingFulfillments])

    // Create an array of line items for each fulfillment
    const fulfillments = existingFulfillments.map(fulfillment => {
        const fulfillmentId = fulfillment.node.id
        const fulfillmentName = fulfillment.node.name
        const trackingInfo = fulfillment.node.trackingInfo
        const lineItems = fulfillment.node.fulfillmentLineItems.edges
        const fulfillmentLineItems = []
        for (let lineItem of lineItems) {
            fulfillmentLineItems.push(returnFulfilledResourceItem(fulfillmentId, fulfillmentName, trackingInfo, lineItem.node))
        }
        return {
            fulfillmentId: fulfillment.node.id,
            fulfillmentName: fulfillment.node.name,
            fulfillmentLineItems
        }
    })
    const fulfillmentMarkup = fulfillments.map(fulfillment => (
        <Card.Section key={fulfillment.fulfillmentId} title={`Fulfillment ${fulfillment.fulfillmentName}`}>
            <ResourceList
                resourceName={{singular: 'fulfilled item', plural: 'fulfilled items'}}
                items={fulfillment.fulfillmentLineItems}
                renderItem={(item) => {
                    const {
                        id, url, name, sku, media, recipientName, recipientPhoneNumber, quantity, recipientAddress,
                        recipientZipCode, recipientCountry, trackingInfo
                    } = item
                    return (
                        <ResourceList.Item
                            id={id}
                            media={media}
                        >
                            <Stack vertical={true}>
                                <Stack distribution={"equalSpacing"} wrap={false}>
                                    <TextContainer>
                                        <TextContainer>
                                            <Stack vertical={true} spacing={"none"}>
                                                <TextStyle variation={"strong"}>{name}</TextStyle>
                                                <TextStyle>SKU: {sku}</TextStyle>
                                                <TextStyle variation={"positive"}><strong>Fulfilled
                                                    Quantity: {quantity}</strong></TextStyle>
                                            </Stack>
                                        </TextContainer>
                                       {/* <Stack> */}
                                        {recipientName === "N/A N/A" ? (
                                                <HaeDomChonInfo first={fulfillmentInfo.order.customer.firstName}
                                                                   last={fulfillmentInfo.order.customer.lastName}
                                                                   email={fulfillmentInfo.order.customer.email}
                                                                   phone={fulfillmentInfo.order.customer.phone}
                                                                   address={fulfillmentInfo.order.shippingAddress}
                                                                   defaultAddress={fulfillmentInfo.order.customer.defaultAddress}
                                                                   /> ) : (
                                                <GiftToKoreaInfo first={fulfillmentInfo.order.customer.firstName}
                                                             last={fulfillmentInfo.order.customer.lastName}
                                                             email={fulfillmentInfo.order.customer.email}
                                                             phone={fulfillmentInfo.order.customer.phone}
                                                             recipientName={recipientName}
                                                             recipientAddress={recipientAddress}
                                                             recipientZipCode={recipientZipCode}
                                                             recipientCountry={recipientCountry}
                                                             recipientPhoneNumber={recipientPhoneNumber}/> ) }
                                        {/* </Stack> */}
                                    </TextContainer>
                                    <Stack vertical={true} distribution={"fill"}>
                                        <Button disabled fullWidth>Fulfilled</Button>
                                        {isFulfillmentMember &&
                                            <LineItemTrackingModal name={name} item={item} updateTracking={true} fulfillmentInfo={fulfillmentInfo}
                                                                   toggleToastActive={toggleToastActive} setToastContent={setToastContent}
                                                                   setExistingFulfillments={setExistingFulfillments}
                                                                   fulfillmentOrderId={fulfillmentInfo.id}
                                            />
                                        }
                                        <Link url={url} external removeUnderline><Button>View Product Page</Button></Link>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </ResourceList.Item>
                    )
                }}
            >
            </ResourceList>
        </Card.Section>
    ))

    return (
        <>
            {fulfillmentMarkup}
        </>
    )
}