import {Navigation} from "@shopify/polaris";
import {HomeMajor, InviteMinor, OrdersMajor, ProfileMajor, ProfileMinor} from "@shopify/polaris-icons";
import {useNavigate} from "react-router-dom";

export default function MANavigation({currentPage}) {
    let navigate = useNavigate()
    return (
        <Navigation location="/">
            <Navigation.Section
                items={[
                    {
                        label: 'Home',
                        onClick() {
                            navigate("/")
                        },
                        icon: HomeMajor,
                        shouldResizeIcon: true,
                        selected: currentPage === "Home"
                    }]}
            />
            <Navigation.Section
                items={[
                    {
                        label: 'Fulfillment Orders',
                        url: "/",
                        onClick() {navigate("/fulfillmentServices")},
                        icon: OrdersMajor,
                        shouldResizeIcon: true,
                        selected: currentPage === "Fulfillment Orders",
                    }
                ]}
            />
            <Navigation.Section
                items={[
                    {
                        label: 'Account Management',
                        url: "/",
                        onClick() {navigate("/accounts")},
                        icon: ProfileMajor,
                        selected: currentPage === "Account Management",
                        shouldResizeIcon: true,
                        subNavigationItems: [
                            {
                                label: 'Fulfillment Services',
                                url: "/",
                                onClick() {navigate("/accounts/fulfillmentServices")},
                                icon: ProfileMinor,
                                selected: currentPage === "Fulfillment Services"
                            },
                            {
                                label: 'Account Invitations',
                                url: "/",
                                onClick() {navigate("/accounts/invitations")},
                                icon: InviteMinor,
                                selected: currentPage === "Account Invitations"
                            }
                        ]
                    },
                ]}
            />
        </Navigation>
    )
}
