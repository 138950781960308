import {useUser} from "../../UserContext";
import Unauthorized from "../Unauthorized";
import PageWrapper from "../views/PageWrapper";
import OMNIAccounts from "../views/OMNI/OMNIAccounts";
import MOAccounts from "../views/Merchant Owners/MOAccounts";
import MAAccounts from "../views/Merchant Admins/MAAccounts";

export default function AccountPathController() {
    const userInfo = useUser()
    const usertype = userInfo.usertype

    let pathToRender;

    switch (usertype) {
        case "OMNI":
            pathToRender = <OMNIAccounts />
            break
        case "MERCHANT OWNER":
            pathToRender = <MOAccounts />
            break
        case "MERCHANT ADMIN":
            pathToRender = <MAAccounts />
            break
        case "FULFILLMENT SERVICE":
            break
        default:
            pathToRender = <Unauthorized />
    }
    return (
        <PageWrapper currentPage={"Account Management"}>
            {pathToRender}
        </PageWrapper>
    )
}