import {Modal, Button, Stack, TextContainer, Form, FormLayout, TextField, Toast, InlineError} from "@shopify/polaris";
import {useState, useCallback} from "react";
import Axios from "axios";

export default function CreateUserModal({name, usertype, shopifyGQLId, serviceDeleted}) {

    const [active, setActive] = useState(false);

    const [emailFieldValue, setEmailFieldValue] = useState("")
    const [emailRequiredMessage, setEmailRequiredMessage] = useState(false)
    const [formError, setFormError] = useState({error: false, message: ""})

    const handleEmailChange = useCallback((value) => {
        setEmailFieldValue(value)
        value.length > 0 ? setEmailRequiredMessage(false) : setEmailRequiredMessage(true)
    }, [])

    const handleChange = useCallback(() => {
        setActive(!active)
        setEmailFieldValue("")
        setEmailRequiredMessage(false)
        setFormError({error: false, message: ""})
        setLoading(false)
    }, [active]);

    const [loading, setLoading] = useState(false)

    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Invitation sent"} onDismiss={toggleToastActive} />
    ) : null
    
    const handleSubmit= useCallback(async () => {
        if (emailFieldValue.trimStart().length === 0) {
            setEmailRequiredMessage(true)
            return
        } else {
            setEmailRequiredMessage(false)
        }

        setFormError({error: false, message: ""})
        setLoading(true)
        try {
            await Axios.post("user/sendCreateUserLink", {
                usertype,
                email: emailFieldValue,
                shopifyGQLId
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setActive(!active)
            toggleToastActive()
            setFormError({error: false, message: ""})
        } catch (e) {
            setFormError({error: true, message: e.response.data.error.message})
        }
        setLoading(false)

    }, [active, emailFieldValue])
    
    const activator = <Button onClick={handleChange} primary disabled={serviceDeleted}>Send Invitation</Button>;

    return (
        <>
            <Modal
                activator={activator}
                open={active}
                onClose={handleChange}
                title={`Create a new ${name} account`}
            >
                <Modal.Section>
                    <Stack vertical={true}>
                        <TextContainer>
                            <p>
                                We'll send an email with a unique link inviting the recipient to register the account.
                                You can revoke these links at any time via the user management page.
                            </p>
                        </TextContainer>
                        <Form onSubmit={handleSubmit}>
                            <FormLayout>
                                <TextField autoFocus={true} label={"Email"} autoComplete={"off"}
                                           value={emailFieldValue} onChange={handleEmailChange}
                                           type={"email"} inputMode={"email"}
                                           error={emailRequiredMessage && "Enter an email"}
                                           focused={true}
                                />
                                <Stack alignment={"trailing"} vertical={true}>
                                    <Button primary submit loading={loading}>Send Invitation</Button>
                                    {formError.error && <InlineError message={formError.message} fieldID={"failedToSendInvitation"} />}
                                </Stack>
                            </FormLayout>
                        </Form>
                    </Stack>
                </Modal.Section>
            </Modal>
            {toastMarkup}
        </>

    );
}