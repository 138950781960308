import {DisplayText} from "@shopify/polaris";
import notFound from "../../assets/images/404.jpg"
import {useUser} from "../UserContext";

import PageWrapper from "./views/PageWrapper";

export default function NotFound404() {
    const userInfo = useUser()
    let userRole = null
    if (userInfo)
        userRole = userInfo.usertype
    const notFoundMarkup =
        <div className={"loginCard"}>
            <DisplayText size={"extraLarge"}>404</DisplayText>
            <img src={notFound} className={"fitContainer"} alt={"missing puzzle piece"}/>
            <DisplayText>The requested URL was not found.</DisplayText>
        </div>

    let pageMarkup

    switch (userRole) {
        case "OMNI":
            pageMarkup =
                <PageWrapper currentPage={"None"}>
                    {notFoundMarkup}
                </PageWrapper>
            break
        default:
            pageMarkup = notFoundMarkup
    }

    return pageMarkup
}