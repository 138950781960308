import {
    Button,
    Card,
    Link,
    ResourceList,
    Stack,
    TextContainer,
    TextStyle,
    EmptyState,
} from "@shopify/polaris";
import LineItemTrackingModal from "./modals/LineItemTrackingModal";
import Axios from "axios";
import {returnUnfulfilledResourceItem} from "../../functions/returnUnfulfilledResourceItem";
import RejectFulfillmentOrderRequestModal from "./modals/RejectFulfillmentOrderRequestModal";
import {useState} from "react";
import CancelFulfillmentOrderModal from "./modals/CancelFulfillmentOrderModal";
import CloseFulfillmentOrderModal from "./modals/CloseFulfillmentOrderModal";
import {useUser} from "../../../../../../UserContext";
import HaeDomChonInfo from "./HaeDamChonInfo";
import GiftToKoreaInfo from "./GiftToKoreaInfo";

export default function SingleFulfillmentUnfulfilledItems({fulfillmentInfo, setFulfillmentInfo, toggleToastActive, setToastContent, setExistingFulfillments}) {

    const userInfo = useUser()
    const usertype = userInfo.usertype
    const isFulfillmentMember = usertype === "FULFILLMENT SERVICE"

    const unfulfilledItems = []

    const [spinner, setSpinner] = useState(false)

    for (let {node} of fulfillmentInfo.lineItems.edges) {
        if (node.remainingQuantity !== 0)
            unfulfilledItems.push(returnUnfulfilledResourceItem(node))
    }

    const updateFulfillmentOrder = async () => {
        const {data} = await Axios.get(`fulfillmentOrders/getSingleFulfillmentOrder?id=${fulfillmentInfo.id}`,  {
            baseURL: process.env.REACT_APP_BASE_URL,
            withCredentials: true,
        })
        setFulfillmentInfo({...data})
    }

    let pageActionMarkup;
    let fulfillmentDisabled;
    switch (fulfillmentInfo.requestStatus) {
        case "SUBMITTED":
            fulfillmentDisabled = true
            pageActionMarkup =
                <Stack distribution={"fillEvenly"}>
                    <RejectFulfillmentOrderRequestModal id={fulfillmentInfo.id} updateFulfillmentOrder={updateFulfillmentOrder}
                                                        toggleToastActive={toggleToastActive} setToastContent={setToastContent} />
                    <Stack distribution={"trailing"}>
                        <Button primary loading={spinner} onClick={async () => {
                            setSpinner(true)
                            try {
                                await Axios.post(`fulfillmentOrders/acceptFulfillmentRequest?id=${fulfillmentInfo.id}`, {},  {
                                    baseURL: process.env.REACT_APP_BASE_URL,
                                    withCredentials: true,
                                })
                                await updateFulfillmentOrder()
                            } catch (e) {
                                setSpinner(false)
                                console.log(e)
                            }
                            setSpinner(false)
                        }}>Accept Fulfillment Request</Button>
                    </Stack>

                </Stack>
            break;
        case "ACCEPTED":
        case "CANCELLATION_REJECTED":
            fulfillmentDisabled = false
            pageActionMarkup =
                <Stack distribution={"fill"}>
                    {/*<HoldFulfillmentModal id={fulfillmentInfo.id} />*/}
                    <CloseFulfillmentOrderModal id={fulfillmentInfo.id} updateFulfillmentOrder={updateFulfillmentOrder}
                                                toggleToastActive={toggleToastActive} setToastContent={setToastContent} />
                </Stack>
            break;
        case "CANCELLATION_REQUESTED":
            fulfillmentDisabled = true
            pageActionMarkup =
                <Stack distribution={"trailing"}>
                    <CancelFulfillmentOrderModal
                        id={fulfillmentInfo.id}
                        updateFulfillmentOrder={updateFulfillmentOrder}
                        toggleToastActive={toggleToastActive} setToastContent={setToastContent} />
                </Stack>
            break;
        case "REJECTED":
            fulfillmentDisabled = true
            pageActionMarkup =
                <Stack distribution={"center"}>
                    <TextStyle variation={"subdued"}>You rejected this order.</TextStyle>
                </Stack>
            break;
        case "CLOSED":
            fulfillmentDisabled = true
            pageActionMarkup =
                <Stack distribution={"center"}>
                    <TextStyle variation={"subdued"}>This order was closed either by you or the merchant. If you would like to make changes to this order, contact the merchant.</TextStyle>
                </Stack>
            break;
        default:
            fulfillmentDisabled = true
    }

    return (
        <>
            <Card.Section>
                {unfulfilledItems.length > 0 ?
                    <ResourceList
                        resourceName={{singular: 'unfulfilled item', plural: 'unfulfilled items'}}
                        items={unfulfilledItems}
                        renderItem={(item) => {
                            const {id, url, name, sku, unfulfilledQuantity, media, recipientName, recipientPhoneNumber,
                                recipientAddress, recipientZipCode, recipientCountry, recipientEmail} = item
                            return (
                                <ResourceList.Item
                                    id={id}
                                    media={media}
                                >
                                    <Stack vertical={true}>
                                        <Stack distribution={"equalSpacing"}>
                                            <TextContainer>
                                                <TextContainer>
                                                    <Stack vertical={true} spacing={"none"}>
                                                        <TextStyle variation={"strong"}>{name}</TextStyle>
                                                        <TextStyle>SKU: {sku}</TextStyle>
                                                        <TextStyle variation={"strong"}>Fulfillable Quantity: {unfulfilledQuantity}</TextStyle>
                                                    </Stack>
                                                </TextContainer>
                                                {/* <Stack> */}
                                        {recipientName === "N/A N/A" ? (
                                                <HaeDomChonInfo first={fulfillmentInfo.order.customer.firstName}
                                                                   last={fulfillmentInfo.order.customer.lastName}
                                                                   email={fulfillmentInfo.order.customer.email}
                                                                   phone={fulfillmentInfo.order.customer.phone}
                                                                   address={fulfillmentInfo.order.shippingAddress}
                                                                   defaultAddress={fulfillmentInfo.order.customer.defaultAddress}
                                                                   /> ) : (
                                                <GiftToKoreaInfo first={fulfillmentInfo.order.customer.firstName}
                                                             last={fulfillmentInfo.order.customer.lastName}
                                                             email={fulfillmentInfo.order.customer.email}
                                                             phone={fulfillmentInfo.order.customer.phone}
                                                             recipientName={recipientName}
                                                             recipientAddress={recipientAddress}
                                                             recipientZipCode={recipientZipCode}
                                                             recipientCountry={recipientCountry}
                                                             recipientPhoneNumber={recipientPhoneNumber}
                                                             recipientEmail={recipientEmail}/> ) }
                                        {/* </Stack> */}
                                            </TextContainer>
                                            <Stack vertical={true} distribution={"fill"}>
                                                {isFulfillmentMember &&
                                                    <LineItemTrackingModal disableModal={fulfillmentDisabled}
                                                                           name={name} item={item} fulfillmentInfo={fulfillmentInfo}
                                                                           fulfillmentOrderId = {fulfillmentInfo.id}
                                                                           updateFulfillmentOrder={updateFulfillmentOrder}
                                                                           setExistingFulfillments={setExistingFulfillments}
                                                                           toggleToastActive={toggleToastActive} setToastContent={setToastContent}
                                                    />
                                                }
                                                <Link url={url} external removeUnderline><Button>View Product Page</Button></Link>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </ResourceList.Item>
                            )
                        }} >
                    </ResourceList>
                    :
                    <EmptyState
                        heading={"No items to fulfill"}
                        image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
                    >
                        {isFulfillmentMember ?
                            <p>The merchant canceled this order. You can only view and update tracking for previously
                                fulfilled items in this order.</p>
                            :
                            <p>This order was canceled by Kim'C Market.
                                Fulfillment members can still view and update tracking for previously fulfilled items in this order.</p>
                        }
                    </EmptyState>
                }

            </Card.Section>
            {unfulfilledItems.length > 0 && isFulfillmentMember &&
                <Card.Section>
                    {pageActionMarkup}
                </Card.Section>
            }
        </>
    )
}