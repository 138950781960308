import {Thumbnail} from "@shopify/polaris";

export const returnFulfilledResourceItem = (fulfillmentId, fulfillmentName, trackingInfo, node) => {

    const recipientFirstName = node.lineItem.customAttributes.find(attribute => attribute.key === "Recipient's First Name")
    const recipientLastName = node.lineItem.customAttributes.find(attribute => attribute.key === "Recipient's Last Name")
    const recipientPhoneNumber = node.lineItem.customAttributes.find(attribute => attribute.key === "Recipient's Phone Number")
    const recipientAddress = node.lineItem.customAttributes.find(attribute => attribute.key === "Recipient's Address")
    const recipientZipCode = node.lineItem.customAttributes.find(attribute => attribute.key === "Recipient's Zip Code")
    const recipientCountry = node.lineItem.customAttributes.find(attribute => attribute.key === "Recipient's Country")
    const recipientEmail = node.lineItem.customAttributes.find(attribute => attribute.key === "Recipient's Email")

    return {
        fulfillmentId,
        fulfillmentName,
        trackingInfo,
        id: node.id,
        shippingAddress: node.shippingAddress,
        url: node.lineItem.product.onlineStoreUrl || node.lineItem.product.onlineStorePreviewUrl,
        name: node.lineItem.name,
        sku: node.lineItem.sku,
        media: (
            <Thumbnail
                source={node.lineItem.image.url}
            />
        ),
        recipientName: `${recipientFirstName ? recipientFirstName.value : "N/A"} ${recipientLastName ? recipientLastName.value
            : "N/A"}`,
            recipientPhoneNumber: recipientPhoneNumber ? recipientPhoneNumber.value : "N/A",
            recipientAddress: recipientAddress ? recipientAddress.value : "N/A",
            recipientZipCode: recipientZipCode ? recipientZipCode.value : "N/A",
            recipientCountry: recipientCountry ? recipientCountry.value : "Korea",
            recipientEmail: recipientEmail ? recipientEmail.value : "Not Provided",
        quantity: node.quantity
    }
}