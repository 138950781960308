import {Modal, Toast, InlineError, Stack, TextContainer, Form, FormLayout, Button, TextField} from "@shopify/polaris"
import {useState, useCallback} from "react";
import Axios from "axios";

export default function DeleteUserModal({serviceDeleted}) {
    const [active, setActive] = useState(false)

    const [emailFieldValue, setEmailFieldValue] = useState("")
    const [emailRequiredMessage, setEmailRequiredMessage] = useState(false)
    const [formError, setFormError] = useState({error: false, message: ""})

    const handleEmailChange = useCallback((value) => {
        setEmailFieldValue(value)
        value.length > 0 ? setEmailRequiredMessage(false) : setEmailRequiredMessage(true)
    }, [])

    const handleChange = useCallback(() => {
        setActive(!active)
        setEmailFieldValue("")
        setEmailRequiredMessage(false)
        setFormError({error: false, message: ""})
        setLoading(false)
    }, [active]);

    const [loading, setLoading] = useState(false)

    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Invitation revoked"} onDismiss={toggleToastActive} />
    ) : null

    const activator = <Button onClick={handleChange} destructive disabled={serviceDeleted}>Delete a user</Button>;

    const handleSubmit= useCallback(async () => {
        if (emailFieldValue.trimStart().length === 0) {
            setEmailRequiredMessage(true)
            return
        } else {
            setEmailRequiredMessage(false)
        }

        setFormError({error: false, message: ""})
        setLoading(true)
        try {
            await Axios.post("user/delete", {
                email: emailFieldValue,
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setActive(!active)
            toggleToastActive()
            setFormError({error: false, message: ""})
        } catch (e) {
            setFormError({error: true, message: e.response.data.error.message})
        }
        setLoading(false)

    }, [active, emailFieldValue])

    return (
        <>
            <Modal
                activator={activator}
                open={active}
                onClose={handleChange}
                title={"Delete a user"}
            >
                <Modal.Section>
                    <Stack vertical={true}>
                        <TextContainer>
                            <p>
                                Enter the email associated with the user to delete.
                            </p>
                        </TextContainer>
                        <Form onSubmit={handleSubmit}>
                            <FormLayout>
                                <TextField autoFocus={true} label={"Email"} autoComplete={"off"}
                                           value={emailFieldValue} onChange={handleEmailChange}
                                           type={"email"} inputMode={"email"}
                                           error={emailRequiredMessage && "Enter an email"}
                                           focused={true}
                                />
                                <Stack alignment={"trailing"} vertical={true}>
                                    <Button destructive submit loading={loading}>Delete User</Button>
                                    {formError.error && <InlineError message={formError.message} fieldID={"failedToDeleteUser"} />}
                                </Stack>
                            </FormLayout>
                        </Form>
                    </Stack>
                </Modal.Section>


            </Modal>
            {toastMarkup}
        </>
    )
}