import {Heading, Button, Checkbox, List, TextContainer, FormLayout, Banner, InlineError, Modal, Select, TextStyle, Toast} from "@shopify/polaris";

import {useCallback, useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";

import Axios from "axios";

export default function DeleteServiceModal(props) {

    useEffect(() => {
        async function getServices() {
            const {data} = await Axios.get("fulfillmentServices/get", {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            return data
        }

        getServices().then(data => {
            const otherServices = data.filter(service => {
                return props.admin_graphql_api_id !== service.admin_graphql_api_id;
            })
            setFulfillmentServices(otherServices)
        })
    }, [props.admin_graphql_api_id])

    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false)
    const handleChange = useCallback(() => {
        setActive(!active)
        setLoading(false)
    }, [active]);

    const [fulfillmentServices, setFulfillmentServices] = useState([])

    const [selected, setSelected] = useState("")
    const handleSelectChange = useCallback((value) => setSelected(value), [])
    const options = [{label: "Do not reassign orders and inventory", value: ""}]
    for (let service of fulfillmentServices)
        options.push({label: service.name, value: service.name})

    const [choiceConfirmed, setChoiceConfirmed] = useState(false)
    const handleChoiceConfirmed = useCallback(newChecked => setChoiceConfirmed(newChecked), [])

    const [errorDeleting, setErrorDeleting] = useState({error: false, message: ""})

    const activator = <Button disabled={props.unmanaged || props.serviceDeleted} destructive fullWidth={true} onClick={handleChange}>Delete service</Button>;

    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(
        () => setToastActive((toastActive) => !toastActive),
        [],
    );
    const toastMarkup = toastActive ? (
        <Toast onDismiss={toggleToastActive} content={`${props.name} deleted.`} />
    ) : null;

    let navigate = useNavigate()
    const deleteService = useCallback(async () => {
        try {
            setErrorDeleting({error: false, message: ""})
            setLoading(true)
            if (!selected)
                await Axios.delete(`fulfillmentServices/deleteFulfillmentService?id=${props.admin_graphql_api_id}`, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
            else {
                const newLocation = fulfillmentServices.filter(service => selected === service.name)
                const {admin_graphql_api_id} = newLocation[0]
                await Axios.delete(`fulfillmentServices/deleteFulfillmentService?id=${props.admin_graphql_api_id}&newLocation=${admin_graphql_api_id}`, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
            }
            setActive(false)
            setChoiceConfirmed(false)
            setLoading(false)
            props.setServiceDeleted(true)
        } catch (e) {
            setLoading(false)
            setErrorDeleting({error: true, message: e.response.data.error.message})
            return
        }

        toggleToastActive()

    }, [navigate, toggleToastActive, fulfillmentServices, props.admin_graphql_api_id, selected])
    return (
        <>
            <Modal
                activator={activator}
                open={active}
                onClose={handleChange}
                title={`Delete ${props.name}?`}
                primaryAction={{
                    content: 'Delete service',
                    destructive: true,
                    disabled: !choiceConfirmed,
                    loading: loading,
                    onAction: async () => {
                        await deleteService()
                    },
                }}
                footer={errorDeleting.error && <InlineError message={errorDeleting.message} fieldID={"deleteServiceError"} />}
            >
                <Modal.Section>
                    <Banner status={"critical"}>
                        <Heading>WARNING! This action will delete any and all Fulfillment Members associated with this service.</Heading>
                    </Banner>
                </Modal.Section>
                <Modal.Section>
                    <FormLayout>
                        <p>Are you sure you want to delete the fulfillment service <strong>{props.name}</strong>? This can’t be undone.</p>
                        <Banner status={"warning"}>
                            <TextContainer>
                                <p>To delete <strong>{props.name}</strong>, you need to select a fulfillment service location to inherit:</p>
                                <List type={"bullet"}>
                                    <List.Item>Inventory stocked at this location</List.Item>
                                    <List.Item>Unfulfilled orders assigned to this location</List.Item>
                                </List>
                            </TextContainer>
                        </Banner>
                        <Select
                            label={"Destination"}
                            options={options}
                            onChange={handleSelectChange}
                            value={selected}
                        />
                        {selected && <TextStyle variation={"warning"}>Inventory and unfulfilled orders at <strong>{props.name}</strong> will
                            be relocated to <strong>{selected}</strong></TextStyle>}
                        {!selected && <TextStyle variation={"negative"}>Inventory and unfulfilled orders at <strong>{props.name}</strong> will be relocated to your store.
                            To move inventory and unfulfilled orders to a new location, select a location using the dropdown menu above.</TextStyle>}
                        <Checkbox label={"Confirm choice"} checked={choiceConfirmed} onChange={handleChoiceConfirmed} />
                    </FormLayout>

                </Modal.Section>
            </Modal>
            {toastMarkup}
        </>

    );
}