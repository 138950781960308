import {Stack, Card, EmptyState, Heading, Frame, IndexTable, Loading, TextStyle} from "@shopify/polaris";
import {useEffect, useState} from "react";
import Axios from "axios";

import {useNavigate} from "react-router-dom";

import US_SKELETON from "../../../../../loading/US_SKELETON";

export default function UnmanagedServices({appFulfillmentServices}) {

    const [unmanagedFulfillmentServices, setUnmanagedFulfillmentServices] = useState([])
    const [pageLoading, setPageLoading] = useState(true)

    useEffect( () => {
        async function fetchData() {
            const {data} = await Axios.get("fulfillmentServices/get?scope=all", {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            return data
        }
        fetchData().then(data => {
            setUnmanagedFulfillmentServices(() => {
                return data.filter(service => {
                    for (let appService of appFulfillmentServices) {
                        if (appService.id === service.id)
                            return false
                    }
                    return true
                })
            })
            setPageLoading(false)
        })
    }, [appFulfillmentServices])

    const emptyStateMarkup =
        <EmptyState
            heading="Here is where you would see fulfillment services managed by other apps"
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
            <p>Your store does not currently have any fulfillment services managed by other apps</p>
        </EmptyState>

    let navigate = useNavigate()
    const rowMarkup = unmanagedFulfillmentServices.map(
        (service, index) => {
            const {id, name, fulfillment_orders_opt_in, tracking_support, inventory_management} = service
            return (
                <IndexTable.Row id={id} key={id} position={index} onNavigation={() => {
                    navigate("/service_details", {state: {...service, unmanaged: true}})
                }}>
                    <IndexTable.Cell>
                        <TextStyle variation={"strong"}><a data-primary-link={""}>{name}</a></TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{fulfillment_orders_opt_in ? "Yes" : "No"}</IndexTable.Cell>
                    <IndexTable.Cell>{tracking_support ? "Yes" : "No"}</IndexTable.Cell>
                    <IndexTable.Cell>{inventory_management ? "Yes" : "No"}</IndexTable.Cell>
                </IndexTable.Row>
            )
        }
    )


    return (
        <>
            {pageLoading ?
                <div style={{height: '100px'}}>
                    <Frame>
                        <Loading/>
                        <US_SKELETON />
                    </Frame>
                </div>
                :
                <>
                    <Stack vertical={true} >
                        <Heading>These fulfillment services are not managed by this app. To manage them, you'll need to
                            use the app that originally created them</Heading>
                        <TextStyle variation={"subdued"}>You can still view detailed information by selecting the
                            unmanaged service's row, but you cannot edit or delete these services</TextStyle>
                        <Card>
                            <IndexTable
                                resourceName={{singular: 'fulfillment service', plural: 'fulfillment services'}}
                                itemCount={unmanagedFulfillmentServices.length}
                                headings={[
                                    {title: "Fulfillment Service"},
                                    {title: "Fulfills Orders"},
                                    {title: "Provides Tracking"},
                                    {title: "Manages Inventory"},
                                ]}
                                selectable={false}
                                emptyState={emptyStateMarkup}
                            >
                                {rowMarkup}
                            </IndexTable>
                        </Card>
                    </Stack>

                </>

            }
        </>
    )
}