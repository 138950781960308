import {Card, DisplayText, Stack, Page} from '@shopify/polaris';
import React from 'react';
import {useNavigate} from "react-router-dom";
import ReactPlayer from "react-player";

export default function DisplayFAQ() {

    let navigate = useNavigate()

    return (
        <Page breadcrumbs={[{content: 'View Fulfillment Orders', onAction() {
                navigate(-1)
            }}]}
                title={"Tutorial & FAQ"}>
            <Stack vertical={true}>
                <Card title={"Video Tutorial"} sectioned>
                    <ReactPlayer controls url={"https://youtu.be/SiTqr1YJhgM"} width={"890px"} height={"501px"}/>
                </Card>
                    <Card title="I haven't received my invite via email, what do I do?" sectioned>
                        <p>Check your spam folder for the invite. It may have gone to spam. If not,
                        please contact us at support@kimcmarket.com</p>
                    </Card>
                    <Card title="I forgot my password." sectioned>
                    <p>Click on 'forgot password' on the login page and enter your email. For further questions or
                    assistance send an email to support@kimcmarket.com</p>
                    </Card>
                    <Card title="I found a bug/error. What can I do?" sectioned>
                    <p>For any bugs or errors found please send us an email to support@kimcmarket.com. We
                    will work to fix any issues with the site as fast as we can.</p>
                    </Card>
            </Stack>
        </Page>
    );
}