import {useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import Axios from "axios";
import {Frame, Toast, Button, DisplayText, TextStyle, Link, Form, FormLayout, InlineError, Stack, TextField} from "@shopify/polaris";
import validator from "validator/es";

export default function OmniRegister() {

    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Omni Account Created"} onDismiss={toggleToastActive} />
    ) : null

    const [accountCreated, setAccountCreated] = useState(false)

    let navigate = useNavigate()

    const [emailFieldValue, setEmailFieldValue] = useState("")
    const [passwordFieldValue, setPasswordFieldValue] = useState("")
    const [confirmPasswordFieldValue, setConfirmPasswordFieldValue] = useState("")
    const [firstNameFieldValue, setFirstNameFieldValue] = useState("")
    const [lastNameFieldValue, setLastNameFieldValue] = useState("")
    const [phoneNumberFieldValue, setPhoneNumberFieldValue] = useState("")
    const [omniKeyFieldValue, setOmniKeyFieldValue] = useState("")

    const [emailRequiredMessage, setEmailRequiredMessage] = useState(false)
    const [passwordRequiredMessage, setPasswordRequiredMessage] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [omniKeyRequiredMessage, setOmniKeyRequiredMessage] = useState(false)

    const [emailNotUniqueMessage, setEmailNotUniqueMessage] = useState({notUnique: false, message: ""})
    const [invalidOmniKeyMessage, setInvalidOmniKeyMessage] = useState({invalid: false, message: ""})

    const [omniRegisterError, setOmniRegisterError] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(false)

    const handleEmailChange = useCallback((value) => {
        setEmailFieldValue(value.trimStart())
        value.length > 0 && setEmailRequiredMessage(false)
        setEmailNotUniqueMessage({notUnique: false, message: ""})
    }, [])
    const handlePasswordChange = useCallback((value) => setPasswordFieldValue(value), [])
    const handleConfirmPasswordChange = useCallback((value) => setConfirmPasswordFieldValue(value), [])
    const handleFirstNameChange = useCallback((value) => setFirstNameFieldValue(value.trimStart()), [])
    const handleLastNameChange = useCallback((value) => setLastNameFieldValue(value.trimStart()), [])
    const handlePhoneNumberChange = useCallback((value) => {
        if (isNaN(value.trimStart()))
            return
        else
            setPhoneNumberFieldValue(value.trimStart())
    }, [])
    const handleOmniKeyChange = useCallback((value) => setOmniKeyFieldValue(value), [])

    const handleLogin = async () => {
        if (emailFieldValue.trimStart().length === 0) {
            setEmailRequiredMessage(true)
            setOmniRegisterError({error: true, message: "Email is required"})
            return
        } else {
            setEmailRequiredMessage(false)
            setOmniRegisterError({error: false, message: ""})
        }

        if (passwordFieldValue.length === 0) {
            setPasswordRequiredMessage(true)
            setOmniRegisterError({error: true, message: "Password is required"})
            return
        } else {
            setPasswordRequiredMessage(false)
            setOmniRegisterError({error: false, message: ""})
        }

        if (passwordFieldValue !== confirmPasswordFieldValue) {
            setConfirmPasswordError(true)
            setOmniRegisterError({error: true, message: "Confirm your password"})
            return
        } else {
            setConfirmPasswordError(false)
            setOmniRegisterError({error: false, message: ""})
        }

        const phoneNumberTrimmed = phoneNumberFieldValue.trimEnd()
        const phoneNumberWithPlus = `+${phoneNumberTrimmed}`
        if (phoneNumberTrimmed.length > 0 && !validator.isMobilePhone(phoneNumberWithPlus, 'any', {strictMode: true})) {
            setOmniRegisterError({error: true, message: `Invalid phone number format. Make sure to include the area code.`})
            setLoading(false)
            return
        }

        if (omniKeyFieldValue.length === 0) {
            setOmniKeyRequiredMessage(true)
            setOmniRegisterError({error: true, message: "OMNI key is required"})
            return
        } else {
            setOmniKeyRequiredMessage(false)
            setOmniRegisterError({error: false, message: ""})
        }

        setLoading(true)
        try {
            const response = await Axios.post("user/register/omni", {
                usertype: "OMNI",
                email: emailFieldValue.trimEnd(),
                password: passwordFieldValue,
                firstName: firstNameFieldValue.trimEnd(),
                lastName: lastNameFieldValue.trimEnd(),
                phoneNumber: phoneNumberFieldValue.trimEnd(),
                OMNI_REQUEST: true,
                OMNI_KEY: omniKeyFieldValue
            },  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })

            if (response.data.message === "Registration Successful") {
                toggleToastActive()
                setAccountCreated(true)
            }
            setOmniRegisterError({error: false, message: ""})
        } catch (e) {
            if (e.response.data.error.type === "email-exists") {
                setEmailNotUniqueMessage({notUnique: true, message: e.response.data.error.message})
                setOmniRegisterError({error: true, message: e.response.data.error.message})
            } else if (e.response.data.error.type === "omni-key-invalid") {
                setInvalidOmniKeyMessage({invalid: true, message: e.response.data.error.message})
                setOmniRegisterError({error: true, message: e.response.data.error.message})
            }
            else
                setOmniRegisterError({error: true, message: "An error has occurred on our end. Contact support."})
        }
        setLoading(false)
    }

    return (
        <Frame>
            <div className={"loginCard"}>
                {
                    !accountCreated ?
                        <>
                            <Stack vertical={true}>
                                <DisplayText size={"extraLarge"}>Create an OMNI Account</DisplayText>
                                <TextStyle variation={"subdued"}>Authorized users only. An OMNI key is required to create an OMNI account</TextStyle>
                            </Stack>
                            <br />
                            <Form onSubmit={handleLogin}>
                                <FormLayout>
                                    <TextField autoFocus={true} label={"Email"} autoComplete={"off"}
                                               value={emailFieldValue} onChange={handleEmailChange}
                                               requiredIndicator={true}
                                               type={"email"} inputMode={"email"}
                                               error={(emailRequiredMessage && "Enter your email")
                                                   || (emailNotUniqueMessage.notUnique && emailNotUniqueMessage.message)}

                                    />
                                    <TextField label={"Password"} autoComplete={"off"}
                                               value={passwordFieldValue} onChange={handlePasswordChange}
                                               requiredIndicator={true}
                                               type={"password"} min={8} minLength={8}
                                               error={passwordRequiredMessage && "Enter your password"}
                                    />
                                    <TextField label={"Confirm Password"} autoComplete={"off"}
                                               value={confirmPasswordFieldValue} onChange={handleConfirmPasswordChange}
                                               requiredIndicator={true}
                                               type={"password"} min={8} minLength={8}
                                               error={confirmPasswordError && "Passwords do not match"}
                                    />
                                    <TextField label={"First Name"} autoComplete={"off"}
                                               value={firstNameFieldValue} onChange={handleFirstNameChange}
                                    />
                                    <TextField label={"Last Name"} autoComplete={"off"}
                                               value={lastNameFieldValue} onChange={handleLastNameChange}
                                    />
                                    <TextField label={"Phone Number"} autoComplete={"off"}
                                               value={phoneNumberFieldValue} onChange={handlePhoneNumberChange}
                                               type={"tel"} inputMode={"tel"}
                                    />
                                    <TextField label={"OMNI Key"} autoComplete={"off"}
                                               value={omniKeyFieldValue} onChange={handleOmniKeyChange}
                                               type={"password"}
                                               error={(omniKeyRequiredMessage && "Enter your OMNI key") ||
                                                   (invalidOmniKeyMessage.invalid && invalidOmniKeyMessage.message)}
                                    />
                                    <Button submit={true} loading={loading} fullWidth primary>Register</Button>
                                    {omniRegisterError.error && <Stack distribution={"center"}>
                                        <InlineError message={omniRegisterError.message} fieldID={"errorCreatingOmniAccount"}/>
                                    </Stack>}
                                </FormLayout>
                            </Form>
                            <br />
                        </>
                        :
                        <Stack vertical={true}>
                            <DisplayText size={"extraLarge"}>OMNI account created!</DisplayText>
                            <Link onClick={() => navigate("../..")}>Click here to login</Link>
                        </Stack>
                }
                {toastMarkup}
            </div>
        </Frame>
    )
}