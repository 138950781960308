import {
    Card,
    FormLayout,
    InlineError,
    ContextualSaveBar,
    TextField,
    Checkbox,
    Banner,
    Toast, Layout, Page
} from "@shopify/polaris";
import Axios from "axios"

import {useState, useRef, useCallback} from "react";

import {useNavigate, useLocation} from "react-router-dom";

import PageWrapper from "../../../PageWrapper";
import AssignedFulfillmentMembers from "./AssignedFulfillmentMembers";
import DeleteServiceModal from "../../DeleteServiceModal";
import CreateUserModal from "../../CreateUserModal";
import DeleteUserModal from "../../DeleteUserModal";

export default function ManageFulfillmentService() {
    const [serviceDeleted, setServiceDeleted] = useState(false)
    const [memberCount, setMemberCount] = useState(0)
    const location = useLocation()
    const defaultState = useRef({
        name: {
            value: location.state.name,
            empty: false
        },
        fulfillmentOrdersOptIn: location.state.fulfillment_orders_opt_in,
        trackingSupport: location.state.tracking_support,
        inventoryManagement: location.state.inventory_management
    })

    const [formErrorState, setFormErrorState] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [saveError, setSaveError] = useState(false)
    const [toastSaveActive, setToastSaveActive] = useState(false);
    const [showBanner, setShowBanner] = useState(true)

    const [name, setName] = useState({
        value: defaultState.current.name.value,
        empty: defaultState.current.name.empty
    })
    const [fulfillmentOrdersOptIn, toggleFulfillsOrders] = useState(defaultState.current.fulfillmentOrdersOptIn)
    const [trackingSupport, toggleTracksOrders] = useState(defaultState.current.trackingSupport)
    const [inventoryManagement, toggleManageInventory] = useState(defaultState.current.inventoryManagement)

    const handleNameChange = useCallback((value) => {
        value = value.trimStart()
        const empty = value.length === 0
        setName(({value, empty}))
        if (empty)
            setFormErrorState(true)
        else
            setFormErrorState(false)
        value && setIsDirty(true)
    }, [])
    const handleFulfillsOrderChange = useCallback((newChecked) => {
        toggleFulfillsOrders(newChecked)
        setIsDirty(true)
    }, [])
    const handleTracksOrderChange = useCallback((newChecked) => {
        toggleTracksOrders(newChecked)
        setIsDirty(true)
    }, [])
    const handleManageInventory = useCallback((newChecked) => {
        toggleManageInventory(newChecked)
        setIsDirty(true)
    }, [])

    const toggleToastSaveActive = useCallback(
        () => setToastSaveActive((toastSaveActive) => !toastSaveActive),
        [],
    );

    const toastSaveMarkup = toastSaveActive ? (
        <Toast onDismiss={toggleToastSaveActive} content="Changes saved" />
    ) : null;

    const handleDiscard = useCallback(() => {
        setName(defaultState.current.name)
        toggleFulfillsOrders(defaultState.current.fulfillmentOrdersOptIn)
        toggleTracksOrders(defaultState.current.trackingSupport)
        toggleManageInventory(defaultState.current.inventoryManagement)
        setIsDirty(false)
        setSaveError(false)
    }, [])

    const handleSave = useCallback(async () => {
        const {admin_graphql_api_id} = location.state
        try {
            await Axios.put("fulfillmentServices/updateFulfillmentService", {
                id: admin_graphql_api_id,
                name: name.value,
                fulfillmentOrdersOptIn,
                trackingSupport,
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
        } catch (e) {
            setSaveError(true)
            return
        }
        defaultState.current.name = {value: name.value, empty: name.empty}
        defaultState.current.fulfillmentOrdersOptIn = fulfillmentOrdersOptIn
        defaultState.current.trackingSupport = trackingSupport
        defaultState.current.inventoryManagement = inventoryManagement
        setToastSaveActive(true);
        setIsDirty(false)
        setSaveError(false)

    }, [name, fulfillmentOrdersOptIn, trackingSupport, inventoryManagement])

    let navigate = useNavigate()
    return (
        <PageWrapper currentPage={"Fulfillment Services"}>
            <Page
                breadcrumbs={[{content: 'View & Edit a Fulfillment Service',
                    onAction() {
                        if (isDirty) {
                            alert("You have unsaved changes. Please discard your changes before leaving.")
                            return
                        }
                        navigate(-1)
                    }
                }]}
                primaryAction={memberCount > 0 && <CreateUserModal name={"Fulfillment Member"} usertype={"FULFILLMENT SERVICE"}
                                                                   shopifyGQLId={location.state.location_id} serviceDeleted={serviceDeleted} />}
                secondaryActions={memberCount > 0 && <DeleteUserModal serviceDeleted={serviceDeleted} />}
                title={name.value}
                fullWidth={true}
            >
                {isDirty && <div style={{height: '25px'}}>
                    <ContextualSaveBar
                        message="Unsaved changes"
                        saveAction={{
                            onAction: handleSave,
                            loading: false,
                            disabled: formErrorState,
                        }}
                        discardAction={{
                            onAction: handleDiscard,
                        }}
                    />
                </div>}
                <Layout>
                    <Layout.Section oneThird>
                        <Card>
                            {saveError &&
                                <Card.Section>
                                    <InlineError message={"Something went wrong"} fieldID={"saveError"} />
                                </Card.Section>
                            }
                            {location.state.unmanaged && showBanner &&
                                <Card.Section>
                                    <Banner title={"Managed by another app"} onDismiss={() => {setShowBanner(false)}} status={"info"}>
                                        <p>This service is managed by another app and therefore cannot be edited or deleted using this app</p>
                                    </Banner>
                                </Card.Section>
                            }
                            <Card.Section>
                                <FormLayout>
                                    <TextField label={"Fulfillment Service Name"} requiredIndicator={true} value={name.value}
                                               onChange={handleNameChange} inputMode={"text"} autoComplete={"off"}
                                               error={name.empty && "The name of the fulfillment service cannot be left blank"}
                                               disabled={location.state.unmanaged || serviceDeleted}
                                    />
                                </FormLayout>
                            </Card.Section>
                            <Card.Section title={"Fulfillment Service Permissions"}>
                                <FormLayout>
                                    <Checkbox label={"Can Fulfill Orders"} checked={fulfillmentOrdersOptIn} onChange={handleFulfillsOrderChange} disabled
                                        // disabled={location.state.unmanaged}
                                              helpText={"Check this option if the service will help you fulfill orders. " +
                                                  "This option is enabled by default and cannot be changed."}/>
                                    <Checkbox label={"Provides Tracking Numbers"} checked={trackingSupport} onChange={handleTracksOrderChange}
                                              disabled={location.state.unmanaged || serviceDeleted}
                                              helpText={"Check this option if the service shares tracking numbers for orders they fulfill."}/>
                                    <Checkbox label={"Can Manage Inventory"} checked={inventoryManagement} onChange={handleManageInventory} disabled
                                        // disabled={location.state.unmanaged}
                                              helpText={"Check this option to allow the service to manage the inventory of items they fulfill. " +
                                                  "This option cannot be changed after a service is created. This option is not supported."}/>
                                </FormLayout>
                            </Card.Section>
                            <Card.Section>
                                <DeleteServiceModal unmanaged={location.state.unmanaged}
                                                    admin_graphql_api_id={location.state.admin_graphql_api_id} serviceDeleted={serviceDeleted}
                                                    name={defaultState.current.name.value} setServiceDeleted={setServiceDeleted}
                                />
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <AssignedFulfillmentMembers name={defaultState.current.name} serviceDeleted={serviceDeleted}
                                                    shopifyGQLId={location.state.location_id} setMemberCount={setMemberCount} />
                    </Layout.Section>
                </Layout>

                {toastSaveMarkup}
            </Page>
        </PageWrapper>
    )
}