import {Modal, Toast, Form, FormLayout, Stack, InlineError, TextField, Checkbox, Button} from "@shopify/polaris";
import {useCallback, useState} from "react";

import Axios from 'axios'

export default function CreateServiceModal() {
    const [active, setActive] = useState(false);
    const [formErrorState, setFormErrorState] = useState(false)
    const [serverError, setServerError] = useState({error: false, message: ""})

    const [name, setName] = useState({
        value: "",
        empty: false
    })
    const [fulfillmentOrdersOptIn, toggleFulfillsOrders] = useState(true)
    const [trackingSupport, toggleTracksOrders] = useState(true)
    const [inventoryManagement, toggleManageInventory] = useState(false)

    const [loading, setLoading] = useState(false)

    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Service Created"} onDismiss={toggleToastActive} />
    ) : null

    const handleNameChange = useCallback((value) => {
        value = value.trimStart()
        const empty = value.length === 0
        setName(prevState => ({value, empty}))
        if (!empty)
            setFormErrorState(false)
    }, [])
    const handleFulfillsOrderChange = useCallback((newChecked) => {
        toggleFulfillsOrders(newChecked)
    }, [])
    const handleTracksOrderChange = useCallback((newChecked) => {
        toggleTracksOrders(newChecked)
    }, [])
    const handleManageInventory = useCallback((newChecked) => {
        toggleManageInventory(newChecked)
    }, [])

    const handleChange = useCallback(() => {
        setActive(!active)
        setName({value: "", empty: false})
        toggleFulfillsOrders(true)
        toggleTracksOrders(true)
        toggleManageInventory(false)
        setFormErrorState(false)
        setServerError({error: false, message: ""})
        setLoading(false)
    }, [active]);

    const handleSubmit = useCallback(async () => {
        setFormErrorState(false)
        setServerError({error: false, message: ""})
        if (name.value.length === 0) {
            setFormErrorState(true)
            setName({value: "", empty: true})
        } else {
            try {
                setLoading(true)
                await Axios.post("fulfillmentServices/create", {
                    name,
                    fulfillmentOrdersOptIn,
                    trackingSupport,
                    inventoryManagement
                }, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
                setLoading(false)
                setActive(!active)
                toggleToastActive()
                setFormErrorState(false)
                setServerError({error: false, message: ""})
            } catch (e) {
                if (e.response.data.error.type === "name") {
                    setServerError({error: true, message: e.response.data.error.message})
                } else {
                    setServerError({error: true, message: "Something went wrong"})
                }
                setLoading(false)
            }

        }
    }, [name, fulfillmentOrdersOptIn, trackingSupport, inventoryManagement])

    const activator = <Button onClick={handleChange} primary>Create Fulfillment Service</Button>;

    return (
        <>
            <Modal
                activator={activator}
                open={active}
                onClose={handleChange}
                title={"Create a new fulfillment service"}
            >
                <Modal.Section>
                    <Form
                        onSubmit={handleSubmit}
                        preventDefault={true}
                    >
                        <FormLayout>
                            <TextField label={"Fulfillment Service Name"} requiredIndicator={true} value={name.value} onChange={handleNameChange} inputMode={"text"} autoComplete={"off"}
                                       error={name.empty && "The name of the fulfillment service cannot be left blank"} focused={true}
                            />
                            <Checkbox label={"Can Fulfill Orders"} checked={fulfillmentOrdersOptIn} disabled onChange={handleFulfillsOrderChange}
                                      helpText={"Check this option if the service will help you fulfill orders. " +
                                          "This option is enabled by default and cannot be changed."}/>
                            <Checkbox label={"Provides Tracking Numbers"} checked={trackingSupport} onChange={handleTracksOrderChange}
                                      helpText={"Check this option if the service shares tracking numbers for orders they fulfill."}/>
                            <Checkbox label={"Can Manage Inventory"} checked={inventoryManagement} onChange={handleManageInventory}
                                      helpText={"Check this option to allow the service to manage the inventory of items they fulfill. " +
                                          "This option cannot be changed after a service is created. This option is not supported."} disabled />

                            <Stack vertical={true} alignment={"center"}>
                                {formErrorState ?
                                    <Button submit primary disabled={true}>Create Service
                                    <InlineError message={"You have unresolved errors in your form. Please resolve the errors before continuing."} fieldID={"errorsExist"} />
                                    </Button>
                                    :
                                    <Button loading={loading} primary submit>Create Service</Button>
                                }
                                {serverError.error && <InlineError message={serverError.message} fieldID={"serverError"} />}
                            </Stack>
                        </FormLayout>
                    </Form>
                </Modal.Section>
            </Modal>
            {toastMarkup}
        </>

    );
}