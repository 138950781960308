import {
    Button,
    Stack,
    FormLayout,
    Banner,
    InlineError,
    Modal,
    TextField
} from "@shopify/polaris";

import {useCallback, useState} from "react";

import {useNavigate} from "react-router-dom";

import Axios from "axios";

export default function CancelFulfillmentOrderModal(props) {

    const [active, setActive] = useState(false);
    const handleChange = useCallback(() => {
        setActive(!active)
        setErrorResponding(false)
    }, [active]);

    const [spinnerAccept, setSpinnerAccept] = useState(false)
    const [spinnerReject, setSpinnerReject] = useState(false)

    const [message, setMessage] = useState("")
    const handleMessageChange = useCallback((value) => {
        value = value.trimStart()
        setMessage(value)
    }, [])

    const [errorResponding, setErrorResponding] = useState(false)

    const activator = <Button onClick={handleChange}>View Cancellation Request</Button>;
    
    let navigate = useNavigate()
    const submitRequest = useCallback(async (endpoint) => {
        try {
            await Axios.post(`fulfillmentOrders/${endpoint}`, {
                id: props.id,
                message: message
            },  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
        } catch (e) {
            setErrorResponding(true)
            return
        }

        if (endpoint === "acceptCancellationRequest")
            props.setToastContent("Cancellation accepted.")
        else if (endpoint === "rejectCancellationRequest")
            props.setToastContent("Cancellation rejected.")

        props.toggleToastActive()
        await props.updateFulfillmentOrder()

    }, [props, message])
    return (
        <Modal
            activator={activator}
            open={active}
            onClose={handleChange}
            title="Respond to Cancellation Request from Merchant"
            footer={errorResponding && <InlineError message={"An error has occurred trying to communicate with the Shopify service"} fieldID={"rejectError"} />}
        >
            <Modal.Section>
                <FormLayout>
                    <Banner status={"info"}>Cancelling the order will prevent fulfillment of any remaining items</Banner>
                    <TextField
                        label={"Message to merchant (optional)"} autoComplete={"off"}
                        value={message} onChange={handleMessageChange}
                    />
                </FormLayout>
            </Modal.Section>
            <Modal.Section>
                <Stack distribution={"equalSpacing"}>
                    <Button destructive loading={spinnerReject} onClick={async () => {
                        setSpinnerReject(true)
                        await submitRequest("rejectCancellationRequest")
                    }}>Reject Cancellation Request</Button>
                    <Button primary loading={spinnerAccept} onClick={async () => {
                        setSpinnerAccept(true)
                        await submitRequest("acceptCancellationRequest")
                    }}>Accept Cancellation Request</Button>
                </Stack>
            </Modal.Section>
        </Modal>
    );
}