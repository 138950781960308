import {
    Button,
    Checkbox,
    FormLayout,
    Banner,
    InlineError,
    Modal,
    Select,
    Toast,
    TextField
} from "@shopify/polaris";

import {useCallback, useState} from "react";

import Axios from "axios";

export default function RejectFulfillmentOrderRequestModal(props) {

    const [active, setActive] = useState(false);
    const handleChange = useCallback(() => {
        setActive(!active)
        setErrorResponding(false)
    }, [active]);

    const [choiceConfirmed, setChoiceConfirmed] = useState(false)
    const handleChoiceConfirmed = useCallback(newChecked => setChoiceConfirmed(newChecked), [])

    const [selected, setSelected] = useState("")
    const handleSelectChange = useCallback((value) => {
        setSelected(value)
        handleChoiceConfirmed(false)
    }, [handleChoiceConfirmed])

    const [otherOption, setOtherOption] = useState("")
    const [otherRequiredMessage, setOtherRequiredMessage] = useState(false)
    const handleOtherChange = useCallback((value) => {
        value = value.trimStart()
        setOtherOption(value)
        handleChoiceConfirmed(false)
        if (value.length === 0)
            setOtherRequiredMessage(true)
        else
            setOtherRequiredMessage(false)
    }, [handleChoiceConfirmed])

    const [errorResponding, setErrorResponding] = useState(false)

    const activator = <Button destructive={true} onClick={handleChange}>Reject Fulfillment Request</Button>;

    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(
        () => setToastActive((toastActive) => !toastActive),
        [],
    );
    const toastMarkup = toastActive ? (
        <Toast onDismiss={toggleToastActive} content={"Fulfillment rejected."} />
    ) : null;

    const submitRequest = useCallback(async () => {
        try {
            await Axios.post("fulfillmentOrders/rejectFulfillmentRequest", {
                id: props.id,
                message: selected.toLowerCase() === "other" ? otherOption : selected
            },  {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
        } catch (e) {
            setErrorResponding(true)
            return
        }

        props.setToastContent("Fulfillment order rejected")
        props.toggleToastActive()
        setActive(false)
        await props.updateFulfillmentOrder()

    }, [toggleToastActive, props.id, selected, otherOption])


    return (
        <>
            <Modal
                activator={activator}
                open={active}
                onClose={handleChange}
                title={"Reject fulfillment order request?"}
                primaryAction={{
                    content: "Reject Fulfillment Request",
                    destructive: true,
                    disabled: !choiceConfirmed,
                    onAction: async () => {
                        await submitRequest()
                    },
                }}
                footer={errorResponding && <InlineError message={"An error has occurred trying to communicate with the Shopify service"} fieldID={"rejectError"} />}
            >
                <Modal.Section>
                    <FormLayout>
                        <Banner status={"critical"}>Are you sure you want to reject this fulfillment order request?</Banner>
                        <Select
                            label={"Rejection reason"}
                            options={[
                                // {label: "Items are out of stock", value: "Items are out of stock"},
                                // {label: "Address(es) of recipient(s) cannot be found", value: "Address(es) of recipient(s) cannot be found"},
                                // {label: "Address(es) of recipient(s) are not located in deliverable areas", value: "Address(es) of recipient(s) are not located in deliverable areas"},
                                {label: "Awaiting payment", value: "AWAITING_PAYMENT"},
                                {label: "High risk of fraud", value: "HIGH_RISK_OF_FRAUD"},
                                {label: "Incorrect address", value: "INCORRECT_ADDRESS"},
                                {label: "Inventory out of stock", value: "INVENTORY_OUT_OF_STOCK"},
                                {label: "Other", value: "other"}
                            ]}
                            onChange={handleSelectChange}
                            placeholder={"Select"}
                            requiredIndicator
                            value={selected}
                        />
                        {selected.toLowerCase() === "other" &&
                            <TextField
                                label={"Other"} autoComplete={"off"}
                                value={otherOption} onChange={handleOtherChange}
                                requiredIndicator
                                error={otherRequiredMessage && "This field is required"}
                                onBlur={() => {
                                    if (otherOption.length === 0)
                                        setOtherRequiredMessage(true)
                                }}
                            />
                        }

                        <Checkbox
                            label={"Confirm choice"}
                            disabled={!selected || (selected.toLowerCase() === "other" && otherOption.length === 0)}
                            checked={choiceConfirmed}
                            onChange={handleChoiceConfirmed} />
                    </FormLayout>

                </Modal.Section>
            </Modal>
            {toastMarkup}
        </>


);
}