import {Page, Stack, IndexTable, EmptyState, Loading, Spinner} from "@shopify/polaris";
import {useState, useEffect} from "react";
import Axios from "axios";
import CreateUserModal from "../CreateUserModal"
import DeleteUserModal from "../DeleteUserModal";
import PageWrapper from "../../PageWrapper";


export default function MerchantAdmins() {

    const [merchantAdmins, setMerchantAdmins] = useState([])
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        setPageLoading(true)
        async function getMerchantAdmins() {
            const {data} = await Axios.get("user/merchantAdmins", {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setMerchantAdmins(data)
        }
        getMerchantAdmins().then(() => {
            setPageLoading(false)
        })
    }, [])

    const rowMarkup = merchantAdmins.map(({id, firstName, lastName, email, phoneNumber, createdAt}, index) => {
        const date = new Date(createdAt)
        const dayMonthYear = new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric", year: "numeric"}).format(date)
        const time = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(date).toLowerCase()
        return (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>{firstName ? firstName : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{lastName ? lastName : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{email}</IndexTable.Cell>
                <IndexTable.Cell>{phoneNumber ? phoneNumber : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{dayMonthYear} at {time}</IndexTable.Cell>
            </IndexTable.Row>
        )
    })

    const loadingMarkup = <>
        <Loading />
        <div style={{width: "500px", margin: "100px auto"}}>
            <Stack vertical={true} alignment={"center"}>
                <Spinner size={"large"} accessibilityLabel={"loadingDashboard"} />
            </Stack>
        </div>
    </>

    return (
        <PageWrapper currentPage={"Merchant Admins"}>
            <Page
                title={"Merchant Admins"}
                primaryAction={<CreateUserModal name={"Merchant Admins"} usertype={"MERCHANT ADMIN"} />}
                secondaryActions={merchantAdmins.length > 0 && <DeleteUserModal />}
                subtitle={"View and manage your Merchant Admins"}
            >
                {pageLoading ?
                    loadingMarkup
                    :
                    (merchantAdmins.length > 0 && !pageLoading ?
                            <IndexTable
                                resourceName={{singular: 'merchant admin', plural: 'merchant admins'}}
                                itemCount={merchantAdmins.length}
                                headings={[
                                    {title: "First Name"},
                                    {title: "Last Name"},
                                    {title: "Email"},
                                    {title: "Phone Number"},
                                    {title: "Date Registered"}
                                ]}
                                selectable={false}
                            >
                                {rowMarkup}
                            </IndexTable>
                            :
                            <EmptyState
                                heading={"No Merchant Admins"}
                                image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
                            >
                                <Stack vertical={true} alignment={"center"}>
                                    <p>We couldn't find any Merchant Admins. Try inviting one!</p>
                                    <CreateUserModal name="Merchant Admin" usertype={"MERCHANT ADMIN"} />
                                </Stack>

                            </EmptyState>
                    )
                }
            </Page>
        </PageWrapper>
    )
}