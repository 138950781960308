import {Page, Card, Stack, Button} from "@shopify/polaris";
import {useNavigate} from "react-router-dom";

export default function MOAccounts() {
    let navigate = useNavigate()
    return (
        <Page
            title={"Accounts Dashboard"}
            subtitle={"Access your store's user account types through this page"}
        >
            <Card title={"Merchant Admins"} sectioned>
                <p>Create a Merchant Admin user or view existing Merchant Admins.</p>
                <Stack distribution={"trailing"}>
                    <Button onClick={() => navigate("/accounts/merchantAdmins")}>View Merchant Admins</Button>
                </Stack>
            </Card>
            <Card title={"Fulfillment Services"} sectioned>
                <p>View a list of fulfillment services connected to your store. You can also send "Fulfillment Member" invitations.</p>
                <p>Fulfillment Members are users who can fulfill items for a fulfillment service you choose.</p>
                <Stack distribution={"trailing"}>
                    <Button onClick={() => navigate("/accounts/fulfillmentServices")}>View Fulfillment Services</Button>
                </Stack>
            </Card>
            <Card title={"Account Invitations"} sectioned>
                <p>View detailed information on what account invitations were sent. You can also revoke pending invitations.</p>
                <Stack distribution={"trailing"}>
                    <Stack distribution={"trailing"}>
                        <Button onClick={() => navigate("/accounts/invitations")}>View Account Invitations</Button>
                    </Stack>
                </Stack>
            </Card>
        </Page>
    )
}