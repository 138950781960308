import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

import enTranslations from '@shopify/polaris/locales/en.json'
import {AppProvider} from "@shopify/polaris";
import {BrowserRouter} from "react-router-dom";
import {UserProvider} from "./components/UserContext";

const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
      <AppProvider i18n={enTranslations}>
          <BrowserRouter>
              <UserProvider>
                <App />
              </UserProvider>
          </BrowserRouter>
      </AppProvider>
  </React.StrictMode>
, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
