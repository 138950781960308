import {
    Page,
    Layout,
    PageActions,
    Collapsible,
    Card,
    EmptyState,
    TextStyle,
    IndexTable,
    Link,
    Loading
} from "@shopify/polaris";

import {useNavigate} from "react-router-dom";

import Axios from "axios";

import {useEffect, useState, useCallback} from "react";

import UnmanagedServices from "./UnmanagedServices";
import PageWrapper from "../../../PageWrapper";

export default function FulfillmentServicesNavigation() {

    let navigate = useNavigate()

    const [fulfillmentServices, setFulfillmentServices] = useState([])
    const [pageLoading, setPageLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const handleToggle = useCallback(() => setOpen(open => !open), [])

    useEffect( () => {
        async function fetchData() {
            const {data} = await Axios.get("fulfillmentServices/get", {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            return data
        }
        fetchData().then(data => {
            setFulfillmentServices(data)
            setPageLoading(false)
        })
    }, [])
    const rowMarkup = fulfillmentServices.map(
        (service, index) => {
            const {admin_graphql_api_id, name, fulfillment_orders_opt_in, tracking_support, inventory_management} = service
            return (
                <IndexTable.Row id={admin_graphql_api_id} key={admin_graphql_api_id} position={index} onNavigation={() => {
                    navigate("/fulfillmentServices/assignedOrders", {state: {...service}})
                }}>
                    <IndexTable.Cell>
                        <TextStyle variation={"strong"}><a data-primary-link={""}>{name}</a></TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{fulfillment_orders_opt_in ? "Yes" : "No"}</IndexTable.Cell>
                    <IndexTable.Cell>{tracking_support ? "Yes" : "No"}</IndexTable.Cell>
                    <IndexTable.Cell>{inventory_management ? "Yes" : "No"}</IndexTable.Cell>
                </IndexTable.Row>
            )
        }
    )

    const linkToFulfillmentServicePage = <Link removeUnderline onClick={() => navigate("/accounts/fulfillmentServices")}>Navigate to Fulfillment Services</Link>

    const emptyStateMarkup =
        <EmptyState
            heading="Create and manage your fulfillment services"
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
            <p>It looks like you don't have any fulfillment services. You can create one by visiting the Fulfillment Services page.</p>
            <Link removeUnderline onClick={() => navigate("/accounts/fulfillmentServices")}>Navigate to Fulfillment Services</Link>
        </EmptyState>

    return (
        <PageWrapper currentPage={"Fulfillment Orders"}>
            {pageLoading ?
                <div style={{height: '100px'}}>
                    <Loading />
                </div>
                :
                <Page
                    title="Your Fulfillment Services"
                    subtitle="You can view detailed information, edit, or delete a fulfillment service by selecting its row"
                    divider={true}
                    primaryAction={fulfillmentServices.length > 0 && linkToFulfillmentServicePage}
                >
                    <Layout>
                        <Layout.Section>
                            <Card>
                                <IndexTable
                                    resourceName={{singular: 'fulfillment service', plural: 'fulfillment services'}}
                                    itemCount={fulfillmentServices.length}
                                    headings={[
                                        {title: "Fulfillment Service"},
                                        {title: "Fulfills Orders"},
                                        {title: "Provides Tracking"},
                                        {title: "Manages Inventory"},
                                    ]}
                                    selectable={false}
                                    emptyState={emptyStateMarkup}
                                >
                                    {rowMarkup}
                                </IndexTable>
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            <PageActions
                                secondaryActions={[
                                    {
                                        content: "Toggle unmanaged services",
                                        onAction() {
                                            handleToggle()
                                        }
                                    },
                                ]}
                            />
                            <Collapsible
                                id={"unmanaged-services-collapsible"}
                                open={open}
                                transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                                expandOnPrint
                            >
                                <UnmanagedServices appFulfillmentServices={fulfillmentServices} />
                            </Collapsible>
                        </Layout.Section>
                    </Layout>
                </Page>
            }
        </PageWrapper>
    );
}