import {useState, useEffect, useCallback} from "react";
import {
    Frame,
    Button,
    DisplayText,
    Form,
    FormLayout,
    InlineError,
    Link,
    Stack,
    TextField,
    TextStyle,
    Toast,
    List
} from "@shopify/polaris";
import {useLocation, useNavigate} from "react-router-dom";
import Axios from "axios";

export default function ResetPassword() {

    const [resetPasswordInfo, setResetPasswordInfo] = useState({})
    let navigate = useNavigate()

    let location = useLocation()
    useEffect(() => {
        const path = location.pathname
        const idStart = path.lastIndexOf("/")
        const resetId = path.substring(idStart + 1)
        async function getResetPasswordInfo() {
            try {
                const {data} = await Axios.get(`user/passwordReset/${resetId}`, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
                setResetPasswordInfo(data)
            } catch (e) {
                setResetPasswordInfo(null)
            }

        }
        getResetPasswordInfo().then()
    }, [location.pathname])
    const [accountCreated, setAccountCreated] = useState(false)
    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Account Created"} onDismiss={toggleToastActive} />
    ) : null


    const [passwordFieldValue, setPasswordFieldValue] = useState("")
    const [confirmPasswordFieldValue, setConfirmPasswordFieldValue] = useState("")


    const [passwordRequiredMessage, setPasswordRequiredMessage] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)


    const [resetError, setResetError] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(false)


    const handlePasswordChange = useCallback((value) => setPasswordFieldValue(value), [])
    const handleConfirmPasswordChange = useCallback((value) => setConfirmPasswordFieldValue(value), [])


    const handleLogin = async () => {
        if (passwordFieldValue.length === 0) {
            setPasswordRequiredMessage(true)
            setResetError({error: true, message: "Password is required"})
            return
        } else {
            setPasswordRequiredMessage(false)
            setResetError({error: false, message: ""})
        }

        if (passwordFieldValue !== confirmPasswordFieldValue) {
            setConfirmPasswordError(true)
            setResetError({error: true, message: "Confirm your password"})
            return
        } else {
            setConfirmPasswordError(false)
            setResetError({error: false, message: ""})
        }

        setLoading(true)
        try {
            const path = location.pathname
            const idStart = path.lastIndexOf("/")
            const resetId = path.substring(idStart + 1)
            await Axios.post(`user/passwordReset/${resetId}`, {
                password: passwordFieldValue,
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })

            toggleToastActive()
            setAccountCreated(true)
            setResetError({error: false, message: ""})
        } catch (e) {
            setLoading(false)
            if (e.response.data.error.type === "email-exists") {
                setResetError({error: true, message: e.response.data.error.message})
            }
            else
                setResetError({error: true, message: "Something went wrong"})
        }
        setLoading(false)
    }

    const forbiddenMarkup =
        <Stack vertical={true}>
            <DisplayText size={"extraLarge"}>Invalid reset link</DisplayText>
            <TextStyle variation={"warning"}>There can be several reasons for this: </TextStyle>
            <List type={"bullet"}>
                <List.Item>The reset link is invalid</List.Item>
                <List.Item>The reset link has been used</List.Item>
                <List.Item>The reset link has expired</List.Item>
                <List.Item>The reset link has been replaced by another reset link (this can happen if you requested more than one reset link, only the latest one will work)</List.Item>
            </List>
        </Stack>
    return (
        <Frame>
            <div className={"loginCard"}>
                {!resetPasswordInfo ? forbiddenMarkup :
                    (
                        !accountCreated ?
                            <>
                                <Stack vertical={true}>
                                    <DisplayText size={"extraLarge"}>Reset your password</DisplayText>
                                    <TextStyle variation={"subdued"}>Kim'C Market Fulfillment Service Portal</TextStyle>
                                </Stack>
                                <br />
                                <Form onSubmit={handleLogin}>
                                    <FormLayout>
                                        <TextField label={"Password"} autoComplete={"off"}
                                                   value={passwordFieldValue} onChange={handlePasswordChange}
                                                   requiredIndicator={true}
                                                   type={"password"} min={8} minLength={8}
                                                   error={passwordRequiredMessage && "Enter your password"}
                                        />
                                        <TextField label={"Confirm Password"} autoComplete={"off"}
                                                   value={confirmPasswordFieldValue} onChange={handleConfirmPasswordChange}
                                                   requiredIndicator={true}
                                                   type={"password"} min={8} minLength={8}
                                                   error={confirmPasswordError && "Passwords do not match"}
                                        />
                                        <Button submit={true} loading={loading} fullWidth primary>Reset Password</Button>
                                        {resetError.error && <Stack distribution={"center"}>
                                            <InlineError message={resetError.message} fieldID={"errorResettingPassword"}/>
                                        </Stack>}
                                    </FormLayout>
                                </Form>
                                <br />
                            </>
                            :
                            <Stack vertical={true}>
                                <DisplayText size={"extraLarge"}>Reset complete!</DisplayText>
                                <Link onClick={() => navigate("/")}>Click here to login</Link>
                            </Stack>
                    )
                }
                {toastMarkup}
            </div>
        </Frame>
    )
}