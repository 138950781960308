import {Stack, IndexTable, EmptyState, Loading, Spinner, Heading} from "@shopify/polaris";
import {useState, useEffect} from "react";
import Axios from "axios";
import CreateUserModal from "../../CreateUserModal";

export default function AssignedFulfillmentMembers(props) {

    const [assignedFulfillmentMembers, setAssignedFulfillmentMembers] = useState([])
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        setPageLoading(true)
        async function getAssignedFulfillmentMembers() {
            const {data} = await Axios.get(`user/assignedFulfillmentMembers?shopifyGQLId=${props.shopifyGQLId}`, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setAssignedFulfillmentMembers(data)
        }
        getAssignedFulfillmentMembers().then(() => {
            setPageLoading(false)
        })
    }, [props.shopifyGQLId])

    const rowMarkup = assignedFulfillmentMembers.map((member, index) => {
        const {id, firstName, lastName, email, phoneNumber, createdAt} = member
        const createdAtDate = new Date(createdAt)
        const dayMonthYear = createdAt ? new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric", year: "numeric"}).format(createdAtDate) : ""
        const time = createdAt ? new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(createdAtDate).toLowerCase() : ""
        return (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>{firstName ? firstName : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{lastName ? lastName : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{email}</IndexTable.Cell>
                <IndexTable.Cell>{phoneNumber ? phoneNumber : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{dayMonthYear} at {time}</IndexTable.Cell>
            </IndexTable.Row>
        )
    })

    const loadingMarkup = <>
        <Loading />
        <div style={{width: "500px", margin: "100px auto"}}>
            <Stack vertical={true} alignment={"center"}>
                <Spinner size={"large"} accessibilityLabel={"Loading members"} />
            </Stack>
        </div>
    </>

    props.setMemberCount(assignedFulfillmentMembers.length)

    return (
        <>
            {pageLoading ?
                loadingMarkup
                :
                (assignedFulfillmentMembers.length > 0 ?
                    <>
                        <Heading>Assigned Fulfillment Members</Heading>
                        <IndexTable
                            resourceName={{singular: 'fulfillment service', plural: 'fulfillment services'}}
                            itemCount={assignedFulfillmentMembers.length}
                            headings={[
                                {title: "First Name"},
                                {title: "Last Name"},
                                {title: "Email"},
                                {title: "Phone Number"},
                                {title: "Date Registered"},
                            ]}
                            selectable={false}
                        >
                            {rowMarkup}
                        </IndexTable>
                    </>
                    :
                    <EmptyState
                        heading={"No Fulfillment Members"}
                        image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
                    >
                        <Stack vertical={true} alignment={"center"}>
                            <p>We couldn't find any Fulfillment Members assigned to {props.name.value}. Try inviting one!</p>
                            <CreateUserModal name={"Fulfillment Member"} usertype={"FULFILLMENT SERVICE"} shopifyGQLId={props.shopifyGQLId} serviceDeleted={props.serviceDeleted} />
                        </Stack>

                    </EmptyState>
                )
            }
        </>
    )
}