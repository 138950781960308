import {useUser} from "../../UserContext";
import Unauthorized from "../Unauthorized";
import PageWrapper from "../views/PageWrapper";
import OMNIHome from "../views/OMNI/OMNIHome";
import MOHome from "../views/Merchant Owners/MOHome";
import ViewAllFulfillmentsRequested
    from "../views/shared/fulfillmentOrders/FulfillmentMemberView/orderFulfillment/ViewAllFulfillmentsRequested";
import MAHome from "../views/Merchant Admins/MAHome";

export default function HomeController() {
    const userInfo = useUser()
    const usertype = userInfo.usertype

    let pathToRender;

    switch (usertype) {
        case "OMNI":
            pathToRender = <OMNIHome />
            break
        case "MERCHANT OWNER":
            pathToRender = <MOHome />
            break
        case "MERCHANT ADMIN":
            pathToRender = <MAHome />
            break
        case "FULFILLMENT SERVICE":
            pathToRender = <ViewAllFulfillmentsRequested />
            break
        default:
            pathToRender = <Unauthorized />
    }
    return (
        <PageWrapper currentPage={"Home"}>
            {pathToRender}
        </PageWrapper>
    )

}