import {Page, ContextualSaveBar, FormLayout, TextField, Stack, InlineError, Toast} from "@shopify/polaris";
import {useState, useEffect, useRef, useCallback} from "react";
import {useUser, useUserUpdate} from "../../../../UserContext";
import Axios from "axios";
import PageWrapper from "../../PageWrapper";
import validator from "validator/es";
import {useNavigate} from "react-router-dom";

export default function AccountInfo() {
    const userInfo = useUser()
    const updateUserInfo = useUserUpdate()
    // get latest updates on user
    useEffect(() => {
        async function getUserInfo() {
            try {
                const {data} = await Axios.get("user/info", {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
                updateUserInfo(data)
                return data
            } catch (e) {

            }
        }
        getUserInfo().then()
    }, [])

    const defaultState = useRef({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phoneNumber: userInfo.phoneNumber,
    })

    const [firstNameFieldValue, setFirstNameFieldValue] = useState(defaultState.current.firstName)
    const [lastNameFieldValue, setLastNameFieldValue] = useState(defaultState.current.lastName)
    const [phoneNumberFieldValue, setPhoneNumberFieldValue] = useState(defaultState.current.phoneNumber)

    const [isDirty, setIsDirty] = useState(false)
    
    const handleFirstNameChange = useCallback((value) => {
        setFirstNameFieldValue(value.trimStart())
        setIsDirty(true)
    }, [])
    const handleLastNameChange = useCallback((value) => {
        setLastNameFieldValue(value.trimStart())
        setIsDirty(true)
    }, [])
    const handlePhoneNumberChange = useCallback((value) => {
        if (isNaN(value.trimStart()))
            return
        else
            setPhoneNumberFieldValue(value.trimStart())
        
        setIsDirty(true)
    }, [])

    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Account updated"} onDismiss={toggleToastActive} />
    ) : null
    
    const [updateError, setUpdateError] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(false)
    
    const handleDiscard = useCallback(() => {
        setFirstNameFieldValue(defaultState.current.firstName)
        setLastNameFieldValue(defaultState.current.lastName)
        setPhoneNumberFieldValue(defaultState.current.phoneNumber)
        setIsDirty(false)
        setUpdateError({error: false, message: ""})
    }, [])


    const handleSave = useCallback(async () => {
        setLoading(true)
        const phoneNumberTrimmed = phoneNumberFieldValue.trimEnd()
        const phoneNumberWithPlus = `+${phoneNumberTrimmed}`
        if (phoneNumberTrimmed.length > 0 && !validator.isMobilePhone(phoneNumberWithPlus, 'any', {strictMode: true})) {
            setUpdateError({error: true, message: `Invalid phone number format. Make sure to include the area code.`})
            setLoading(false)
            return
        }

        try {
            await Axios.patch("user/update", {
                firstName: firstNameFieldValue.trimEnd(),
                lastName: lastNameFieldValue.trimEnd(),
                phoneNumber: phoneNumberFieldValue.trimEnd(),
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            toggleToastActive()
            setUpdateError({error: false, message: ""})
        } catch (e) {
            if (e.response.data.error.type === "email-exists") {
                setUpdateError({error: true, message: e.response.data.error.message || "Something went wrong"})
            }
            else {
                setUpdateError({error: true, message: e.response.data.error.message || "Something went wrong"})
            }
            setLoading(false)
            return
        }
        defaultState.current.firstName = firstNameFieldValue.trimEnd()
        defaultState.current.lastName = lastNameFieldValue.trimEnd()
        defaultState.current.phoneNumber = phoneNumberFieldValue.trimEnd()
        setFirstNameFieldValue(firstNameFieldValue.trimEnd())
        setLastNameFieldValue(lastNameFieldValue.trimEnd())
        setPhoneNumberFieldValue(phoneNumberFieldValue.trimEnd())
        setIsDirty(false)
        setLoading(false)
    }, [firstNameFieldValue, lastNameFieldValue, phoneNumberFieldValue, toggleToastActive])

    let navigate = useNavigate()

    return (
        <PageWrapper currentPage={"Account Info"}>
            <Page
                title={"View and update your account information"}
                narrowWidth={true}
                breadcrumbs={[{content: 'Go back', onAction() {
                        navigate(-1)
                    }}]}
            >
                {isDirty && <div style={{height: '25px'}}>
                    <ContextualSaveBar
                        message="Unsaved changes"
                        saveAction={{
                            onAction: handleSave,
                            loading: loading,
                        }}
                        discardAction={{
                            onAction: handleDiscard,
                        }}
                    />
                </div>}
                <FormLayout>
                    <TextField autoFocus={true} label={"Email"} autoComplete={"off"}
                               value={userInfo.email}
                               disabled helpText={"You will need to request a new account if you want to change your email"}
                               type={"email"} inputMode={"email"}

                    />
                    <TextField label={"First Name"} autoComplete={"off"}
                               value={firstNameFieldValue} onChange={handleFirstNameChange}
                    />
                    <TextField label={"Last Name"} autoComplete={"off"}
                               value={lastNameFieldValue} onChange={handleLastNameChange}
                    />
                    <TextField label={"Phone Number"} autoComplete={"off"}
                               value={phoneNumberFieldValue} onChange={handlePhoneNumberChange}
                               type={"tel"} inputMode={"tel"} helpText={"Include the area code"}
                    />

                    {updateError.error && <Stack distribution={"center"}>
                        <InlineError message={updateError.message} fieldID={"errorUpdatingAccountInfo"}/>
                    </Stack>}
                </FormLayout>
                {toastMarkup}
            </Page>
        </PageWrapper>
    )
}