import {Modal, Button, Stack, TextContainer, Form, FormLayout, TextField, Toast, InlineError, Link} from "@shopify/polaris";
import {useState, useCallback} from "react";
import Axios from "axios";

export default function ForgotPasswordModal() {

    const [active, setActive] = useState(false);

    const [emailFieldValue, setEmailFieldValue] = useState("")
    const [emailRequiredMessage, setEmailRequiredMessage] = useState(false)
    const [formError, setFormError] = useState({error: false, message: ""})

    const handleEmailChange = useCallback((value) => {
        setEmailFieldValue(value)
        value.length > 0 ? setEmailRequiredMessage(false) : setEmailRequiredMessage(true)
    }, [])

    const handleChange = useCallback(() => {
        setActive(!active)
        setEmailFieldValue("")
        setEmailRequiredMessage(false)
        setFormError({error: false, message: ""})
        setLoading(false)
    }, [active]);

    const [loading, setLoading] = useState(false)

    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), [])
    const toastMarkup = toastActive ? (
        <Toast content={"Email sent"} onDismiss={toggleToastActive} />
    ) : null

    const handleSubmit= useCallback(async () => {
        if (emailFieldValue.trimStart().length === 0) {
            setEmailRequiredMessage(true)
            return
        } else {
            setEmailRequiredMessage(false)
        }

        setFormError({error: false, message: ""})
        setLoading(true)
        try {
            await Axios.post("user/sendPasswordResetLink", {
                email: emailFieldValue,
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setActive(!active)
            toggleToastActive()
            setFormError({error: false, message: ""})
        } catch (e) {
            setLoading(false)
            setFormError({error: true, message: e.response.data.error ? e.response.data.error.message : "Something went wrong"})
        }
        setLoading(false)

    }, [active, emailFieldValue, toggleToastActive])

    const activator = <Link onClick={handleChange}>Forgot password?</Link>;

    return (
        <>
            <Modal
                activator={activator}
                open={active}
                onClose={handleChange}
                title={"Forgot your password?"}
            >
                <Modal.Section>
                    <Stack vertical={true}>
                        <TextContainer>
                            <p>
                                Enter the email associated with your account. 
                                If an account exists, we'll send you instructions on how to reset your password.
                            </p>
                        </TextContainer>
                        <Form onSubmit={handleSubmit}>
                            <FormLayout>
                                <TextField autoFocus={true} label={"Email"} autoComplete={"off"}
                                           value={emailFieldValue} onChange={handleEmailChange}
                                           type={"email"} inputMode={"email"}
                                           error={emailRequiredMessage && "Enter an email"}
                                />
                                <Stack alignment={"trailing"} vertical={true}>
                                    <Button primary submit loading={loading}>Request Password Reset</Button>
                                    {formError.error && <InlineError message={formError.message} fieldID={"failedToSendPasswordReset"} />}
                                </Stack>
                            </FormLayout>
                        </Form>
                    </Stack>
                </Modal.Section>
            </Modal>
            {toastMarkup}
        </>

    );
}