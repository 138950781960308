import {Card, SkeletonTabs} from "@shopify/polaris";

export default function US_SKELETON() {
    return (
        <Card>
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
            <SkeletonTabs count={4} />
        </Card>
    )
}