import {useUser} from "../../UserContext";
import Unauthorized from "../Unauthorized";
import FulfillmentServicesNavigation from "../views/shared/fulfillmentOrders/AdminView/FulfillmentServicesNavigation";
import ViewAllFulfillmentsRequested
    from "../views/shared/fulfillmentOrders/FulfillmentMemberView/orderFulfillment/ViewAllFulfillmentsRequested";

export default function FulfillmentOrdersController() {
    const userInfo = useUser()
    const usertype = userInfo.usertype

    let pathToRender;


    switch (usertype) {
        case "OMNI":
        case "MERCHANT OWNER":
        case "MERCHANT ADMIN":
            pathToRender = <FulfillmentServicesNavigation />
            break
        case "FULFILLMENT SERVICE":
            pathToRender = <ViewAllFulfillmentsRequested />
            break
        default:
            pathToRender = <Unauthorized />
    }
    return pathToRender
}