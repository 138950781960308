import {Stack, TextContainer, TextStyle} from "@shopify/polaris";

export default function GiftToKoreaInfo({first, last, email, phone, recipientName, recipientAddress, recipientZipCode, recipientCountry,
                                    recipientPhoneNumber, recipientEmail}){
    return (
        <Stack>
            <TextContainer>
                <Stack vertical={true} spacing={"none"}>
                    <TextStyle variation={"strong"}>Purchased By:</TextStyle>
                    <TextStyle>{`${first} ${last}`}</TextStyle>
                    <TextStyle>{email != null ?
                        `Email: ${email}` :
                        `Phone: ${phone}`}</TextStyle>
                </Stack>
            </TextContainer>
            <TextContainer>
                <Stack vertical={true} spacing={"none"}>
                    <TextStyle variation={"strong"}>Deliver to:</TextStyle>
                    <TextStyle>{recipientName}</TextStyle>
                    <TextStyle>{recipientAddress}</TextStyle>
                    {recipientZipCode && <TextStyle>{recipientZipCode}</TextStyle>}
                    <TextStyle>{recipientCountry}</TextStyle>
                    <TextStyle>Phone: {recipientPhoneNumber}</TextStyle>
                    <TextStyle>Email: {recipientEmail}</TextStyle>
                </Stack>
            </TextContainer>
        </Stack>
);
}
