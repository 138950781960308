import {Stack, TextContainer, TextStyle} from "@shopify/polaris";

export function HaeDomChonInfo({first, last, email, phone, address, defaultAddress}) {
        return (
            <Stack>
                <TextContainer>
                    <Stack vertical={true} spacing={"none"}>
                        <TextStyle variation={"strong"}>Purchased By:</TextStyle>
                        <TextStyle>{`${first} ${last}`}</TextStyle>
                        <TextStyle>{email != null ?
                            `Email: ${email}` :
                            `Email: Not Provided`}</TextStyle>
                        <TextStyle>{phone != null ?
                            `Phone: ${phone}` :
                            `Phone: ${defaultAddress.phone}`}</TextStyle>
                    </Stack>
                </TextContainer>
                <TextContainer>
                    <Stack vertical={true} spacing={"none"}>
                        <TextStyle variation={"strong"}>Deliver To:</TextStyle>
                        <TextStyle>{`${address.firstName} ${address.lastName}`}</TextStyle>
                        <TextStyle>{address.address1} {address.address2}</TextStyle>
                        <TextStyle>{address.city} {address.province} {address.zip} {address.country}</TextStyle>
                        <TextStyle>{address.phone != null ?
                            `Phone: ${address.phone}` :
                            `Phone: Not Provided`}</TextStyle>
                    </Stack>
                </TextContainer>
            </Stack>
        );
}

export default HaeDomChonInfo;