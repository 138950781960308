import {InlineError, Form, Stack, Frame, Button, TextField, FormLayout, DisplayText, Link} from "@shopify/polaris";
import "../../styles/Login.css"
import {useCallback, useState} from "react";
import Axios from "axios"
import {useUserUpdate} from "../UserContext";
import {useNavigate} from "react-router-dom";
import ForgotPasswordModal from "../ForgotPasswordModal";

export default function Login() {
    const updateUserInfo = useUserUpdate()
    let navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [emailFieldValue, setEmailFieldValue] = useState("")
    const [passwordFieldValue, setPasswordFieldValue] = useState("")
    const [emailRequiredMessage, setEmailRequiredMessage] = useState(false)
    const [passwordRequiredMessage, setPasswordRequiredMessage] = useState(false)

    const [loginError, setLoginError] = useState(false)

    const handleEmailChange = useCallback((value) => setEmailFieldValue(value), [])
    const handlePasswordChange = useCallback((value) => setPasswordFieldValue(value), [])

    const handleLogin = async () => {
        if (emailFieldValue.trimStart().length === 0) {
            setEmailRequiredMessage(true)
            return
        } else {
            setEmailRequiredMessage(false)
        }

        if (emailFieldValue.toLowerCase() === "omni@omni") navigate("/register/omni")

        if (passwordFieldValue.length === 0) {
            setPasswordRequiredMessage(true)
            return
        } else {
            setPasswordRequiredMessage(false)
        }

        setLoading(true)
        setLoginError(false)
        try {
            const {data} = await Axios.post("user/login", {
                email: emailFieldValue,
                password: passwordFieldValue
            }, {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })

            updateUserInfo(data)
        } catch (e) {
            setLoginError(true)
        }
        setLoading(false)
    }

    return (
        <Frame>
            <div className={"loginCard"}>
                <Stack vertical={true}>
                    <DisplayText size={"extraLarge"}>Kim'C Market</DisplayText>
                    <DisplayText>Login to the Fulfillment Service Portal</DisplayText>
                </Stack>
                <br />
                <Form onSubmit={handleLogin}>
                    <FormLayout>
                        <TextField autoFocus={true} label={"Email"} autoComplete={"off"}
                                   value={emailFieldValue} onChange={handleEmailChange}
                                   type={"email"} inputMode={"email"}
                                   error={emailRequiredMessage && "Enter your email"}
                        />
                        <TextField label={"Password"} autoComplete={"off"}
                                   value={passwordFieldValue} onChange={handlePasswordChange}
                                   type={"password"} min={8} minLength={8}
                                   error={passwordRequiredMessage && "Enter your password"}
                        />
                        <Button submit={true} loading={loading} fullWidth primary>Login</Button>
                        {loginError && <Stack distribution={"center"}>
                            <InlineError message={"Email or password is incorrect"} fieldID={"incorrectLoginCredentials"}/>
                        </Stack>}
                    </FormLayout>
                </Form>
                <br />
                <Stack distribution={"center"}>
                    <ForgotPasswordModal />
                    <Link url={"/FAQ"}>Tutorial & FAQ</Link>
                </Stack>
            </div>
        </Frame>
    )
}