import {EmptyState, Stack} from "@shopify/polaris";
import "../../styles/Login.css"

export default function Unauthorized() {

    return (
        <div className={"loginCard"}>
            <Stack vertical={true}>
                <EmptyState
                    heading={"Unauthorized access"}
                    image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
                >
                    <p>You don't have access to this page</p>
                </EmptyState>
            </Stack>
        </div>
    )
}