import {Badge} from "@shopify/polaris";

export function returnStatusFulfillmentBadge(status, requestStatus) {
    switch (status) {
        case "CLOSED":
            if (requestStatus === "ACCEPTED")
                return <Badge status={"success"}>Fulfilled</Badge>
            else if (requestStatus === "CANCELLATION_REQUESTED") {
                return <Badge>Canceled</Badge>
            }
            else if (requestStatus === "CLOSED")
                return <Badge>Reopened</Badge>
            break
        case "OPEN":
        case "IN_PROGRESS":
            return <Badge status={"attention"}>Unfulfilled</Badge>
        case "INCOMPLETE":
            return <Badge status={"warning"}>Incomplete</Badge>
        case "CANCELLED":
            return <Badge>Canceled</Badge>
        default:
            return <Badge>{status}</Badge>
    }
}

export function returnStatusRequestBadge(status, fulfillmentStatus) {
    switch (status) {
        case "SUBMITTED":
            return <Badge status={"attention"}>Pending Acceptance</Badge>
        case "ACCEPTED":
            return <Badge status={"success"}>Accepted</Badge>
        case "REJECTED":
            return <Badge>Rejected</Badge>
        case "CLOSED":
            return <Badge>Closed</Badge>
        case "CANCELLATION_REQUESTED":
            return <Badge status={"warning"}>Cancellation Requested</Badge>
        case "CANCELLATION_ACCEPTED":
            return <Badge>Cancellation Accepted</Badge>
        case "CANCELLATION_REJECTED":
            return <Badge>Cancellation Rejected</Badge>;
        case "UNSUBMITTED":
            return <Badge>Unsubmitted</Badge>
        default:
            return <Badge>{status}</Badge>
    }
}