import {Page, Stack, IndexTable, EmptyState, Loading, Spinner, TextStyle} from "@shopify/polaris";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Axios from "axios";
import CreateServiceModal from "../../CreateServiceModal";
import PageWrapper from "../../../PageWrapper";

export default function FulfillmentServices() {
    const [fulfillmentServices, setFulfillmentServices] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    useEffect(() => {
        setPageLoading(true)
        async function getFulfillmentServices() {
            const {data} = await Axios.get("fulfillmentServices/get", {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setFulfillmentServices(data)
        }
        getFulfillmentServices().then(() => {
            setPageLoading(false)
        })
    }, [])
    let navigate = useNavigate()
    const rowMarkup = fulfillmentServices.map((service, index) => {
        const {admin_graphql_api_id, location_id, name, fulfillment_orders_opt_in, tracking_support, inventory_management} = service
        return (
            <IndexTable.Row id={location_id} key={location_id} position={index} onNavigation={() => {
                navigate("./manage", {state: {...service}})
            }}>
                <IndexTable.Cell>
                    <TextStyle variation={"strong"}><a data-primary-link={""}>{name}</a></TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{fulfillment_orders_opt_in ? "Yes" : "No"}</IndexTable.Cell>
                <IndexTable.Cell>{tracking_support ? "Yes" : "No"}</IndexTable.Cell>
                <IndexTable.Cell>{inventory_management ? "Yes" : "No"}</IndexTable.Cell>
            </IndexTable.Row>
        )
    })

    const loadingMarkup = <>
        <Loading />
        <div style={{width: "500px", margin: "100px auto"}}>
            <Stack vertical={true} alignment={"center"}>
                <Spinner size={"large"} accessibilityLabel={"loadingDashboard"} />
            </Stack>
        </div>
    </>

    return (
        <PageWrapper currentPage={"Fulfillment Services"}>
            <Page
                title={"Fulfillment Services"}
                primaryAction={<CreateServiceModal />}
                subtitle={"View and manage your Fulfillment Services. Select a service to manage it and its members."}
            >
                {pageLoading ?
                    loadingMarkup
                    :
                    (fulfillmentServices.length > 0 && !pageLoading ?
                            <IndexTable
                                resourceName={{singular: 'fulfillment service', plural: 'fulfillment services'}}
                                itemCount={fulfillmentServices.length}
                                headings={[
                                    {title: "Service"},
                                    {title: "Fulfills Orders"},
                                    {title: "Provides Tracking"},
                                    {title: "Manages Inventory"},
                                ]}
                                selectable={false}
                            >
                                {rowMarkup}
                            </IndexTable>
                            :
                            <EmptyState
                                heading={"No Fulfillment Services"}
                                image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
                            >
                                <Stack vertical={true} alignment={"center"}>
                                    <p>We couldn't find any Fulfillment Services. Try creating one!</p>
                                    <CreateServiceModal />
                                </Stack>

                            </EmptyState>
                    )
                }
            </Page>
        </PageWrapper>
    )
}