import {Page, Layout, Card, Stack, Button} from "@shopify/polaris"
import {useNavigate} from "react-router-dom";

export default function OMNIHome() {
    let navigate = useNavigate()
    return (
        <Page
            title={"OMNI Dashboard"}
            subtitle={"Access primary services through this page"}
        >
            <Layout>
                <Layout.Section oneHalf>
                    <Card title={"Fulfillment Orders"} sectioned>
                        <p>Visit the Fulfillment Orders dashboard to view orders requested by your store to one or more Fulfillment Services.</p>
                        <Stack distribution={"trailing"}>
                            <Button onClick={() => navigate("/fulfillmentServices")}>View Fulfillment Orders</Button>
                        </Stack>
                    </Card>
                    <Card title={"Accounts"} sectioned>
                        <p>Visit the Account Management page to view the types of accounts you can manage.</p>
                        <Stack distribution={"trailing"}>
                            <Button onClick={() => navigate("/accounts")}>View Accounts</Button>
                        </Stack>
                    </Card>
                    <Card title={"Account Invitations"} sectioned>
                        <p>View detailed information on what account invitations were sent. You can also revoke pending invitations.</p>
                        <Stack distribution={"trailing"}>
                            <Button onClick={() => navigate("/accounts/invitations")}>View Account Invitations</Button>
                        </Stack>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    )
}