import './styles/App.css';
import React from "react";
import {Routes, Route} from "react-router-dom";
import Axios from "axios";
import {useEffect} from "react";
import {useUser, useUserUpdate} from "./components/UserContext";

import NotFound404 from "./components/pages/NotFound404";

import Login from "./components/pages/Login"
import ResetPassword from "./components/pages/ResetPassword";
import DisplayFAQ from "./components/pages/FAQ";

import Unauthorized from "./components/pages/Unauthorized";
import OmniRegister from "./components/pages/OmniRegister";
import RegisterUser from "./components/pages/RegisterUser";

import HomeController from "./components/pages/page-controllers/HomeController";
import FulfillmentOrdersController from "./components/pages/page-controllers/FulfillmentOrdersController";
import AccountPathController from "./components/pages/page-controllers/AccountPathController";

import AssignedFulfillmentOrders
    from "./components/pages/views/shared/fulfillmentOrders/AdminView/Orders/AssignedFulfillmentOrders";
import ViewSingleFulfillment from "./components/pages/views/shared/fulfillmentOrders/FulfillmentMemberView/orderFulfillment/ViewSingleFulfillment";
import ManageFulfillmentService
    from "./components/pages/views/shared/accountManagement/FulfillmentServices/ManageFulfillmentService";

import MerchantOwners from "./components/pages/views/shared/accountManagement/MerchantOwners";
import MerchantAdmins from "./components/pages/views/shared/accountManagement/MerchantAdmins";
import FulfillmentServices from "./components/pages/views/shared/accountManagement/FulfillmentServices/FulfillmentServices";
import AccountInvitations from "./components/pages/views/shared/accountManagement/AccountInvitations";
import AccountInfo from "./components/pages/views/shared/accountManagement/AccountInfo";

function App() {
    const userInfo = useUser()
    const updateUserInfo = useUserUpdate()
    useEffect(() => {
        async function getUserInfo() {
            try {
                const {data} = await Axios.get("user/info", {
                    baseURL: process.env.REACT_APP_BASE_URL,
                    withCredentials: true,
                })
                updateUserInfo(data)
                return data
            } catch (e) {

            }
        }
        getUserInfo().then()
    }, [])
    return (
        <div className="App">
            <Routes>
                <Route path="*" element={<NotFound404 />} />

                {/* Controller related routes */}
                <Route path={"/"} element={userInfo ? <HomeController /> : <Login />} />
                <Route path={"/fulfillmentServices"} element={userInfo ? <FulfillmentOrdersController /> : <Login />} />
                <Route path={"/accounts"} element={userInfo ? <AccountPathController /> : <Login />} />
                {/* End controller related routes */}

                {/* Fulfillment related routes */}
                <Route path={"/fulfillmentServices/assignedOrders"} element={userInfo ? <AssignedFulfillmentOrders /> : <Login />} />
                <Route path={"/fulfillmentServices/assignedOrders/fulfillmentOrder"} element={userInfo ? <ViewSingleFulfillment /> : <Login />} />
                {/* End fulfillment related routes */}

                {/* Account related routes */}
                <Route path={"/login"} element={<Login />} />
                <Route path={"/forbidden"} element={userInfo ? <Unauthorized /> : <Login />} />
                <Route path={"/faq"} element={<DisplayFAQ />} />
                <Route path={"/register/omni"} element={<OmniRegister />} />
                <Route path={"/register/:id"} element={<RegisterUser />} />
                <Route path={"/resetPassword/:id"} element={<ResetPassword />} />
                <Route path={"/info"} element={userInfo ? <AccountInfo /> : <Login />} />
                <Route path={"/accounts/merchantOwners"} element={userInfo ? <MerchantOwners /> : <Login />} />
                <Route path={"/accounts/merchantAdmins"} element={userInfo ? <MerchantAdmins /> : <Login />} />
                <Route path={"/accounts/fulfillmentServices"} element={userInfo ? <FulfillmentServices /> : <Login />} />
                <Route path={"/accounts/fulfillmentServices/manage"} element={userInfo ? <ManageFulfillmentService /> : <Login />} />
                <Route path={"/accounts/invitations"} element={userInfo ? <AccountInvitations /> : <Login />} />
                {/* End account related routes */}
            </Routes>
        </div>
    )
}

export default App;
