import {Page, Stack, IndexTable, EmptyState, Loading, Spinner} from "@shopify/polaris";
import {useState, useEffect} from "react";
import Axios from "axios";
import CreateUserModal from "../CreateUserModal"
import DeleteUserModal from "../DeleteUserModal";
import PageWrapper from "../../PageWrapper";


export default function MerchantOwners() {

    const [merchantOwners, setMerchantOwners] = useState([])
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        setPageLoading(true)
        async function getMerchantOwners() {
            const {data} = await Axios.get("user/merchantOwners", {
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            setMerchantOwners(data)
        }
        getMerchantOwners().then(() => {
            setPageLoading(false)
        })
    }, [])

    const rowMarkup = merchantOwners.map(({id, firstName, lastName, email, phoneNumber, createdAt}, index) => {
        const date = new Date(createdAt)
        const dayMonthYear = new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric", year: "numeric"}).format(date)
        const time = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(date).toLowerCase()
        return (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>{firstName ? firstName : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{lastName ? lastName : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{email}</IndexTable.Cell>
                <IndexTable.Cell>{phoneNumber ? phoneNumber : "Not added"}</IndexTable.Cell>
                <IndexTable.Cell>{dayMonthYear} at {time}</IndexTable.Cell>
            </IndexTable.Row>
        )
    })

    const loadingMarkup = <>
        <Loading />
        <div style={{width: "500px", margin: "100px auto"}}>
            <Stack vertical={true} alignment={"center"}>
                <Spinner size={"large"} accessibilityLabel={"loadingDashboard"} />
            </Stack>
        </div>
    </>

    return (
        <PageWrapper currentPage={"Merchant Owners"}>
            <Page
                title={"Merchant Owners"}
                primaryAction={<CreateUserModal name={"Merchant Owner"} usertype={"MERCHANT OWNER"} />}
                secondaryActions={merchantOwners.length > 0 && <DeleteUserModal />}
                subtitle={"View and manage your Merchant Owners based on their store (currently there can only be one merchant owner)"}
            >
                {pageLoading ?
                    loadingMarkup
                    :
                    (merchantOwners.length > 0 ?
                            <IndexTable
                                resourceName={{singular: 'merchant owner', plural: 'merchant owners'}}
                                itemCount={merchantOwners.length}
                                headings={[
                                    {title: "First Name"},
                                    {title: "Last Name"},
                                    {title: "Email"},
                                    {title: "Phone Number"},
                                    {title: "Date Registered"}
                                ]}
                                selectable={false}
                            >
                                {rowMarkup}
                            </IndexTable>
                            :
                            <EmptyState
                                heading={"No Merchant Owners"}
                                image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
                            >
                                <Stack vertical={true} alignment={"center"}>
                                    <p>We couldn't find any Merchant Owners. Try inviting one!</p>
                                    <CreateUserModal name="Merchant Owner" usertype={"MERCHANT OWNER"} />
                                </Stack>

                            </EmptyState>
                    )
                }


            </Page>
        </PageWrapper>
    )
}