import {Loading, DisplayText, Heading, Pagination, Page, Stack, Card, IndexTable, TextStyle} from "@shopify/polaris";
import {useNavigate, useLocation} from "react-router-dom";
import Axios from "axios";
import {useEffect, useState} from "react";

import {returnStatusRequestBadge, returnStatusFulfillmentBadge} from "../../functions/returnStatusBadge"
import PageWrapper from "../../../../PageWrapper";

export default function AssignedFulfillmentOrders() {

    let location = useLocation()
    let serviceLocationId = location.state.location_id
    let navigate = useNavigate()

    const [fulfillments, setFulfillments] = useState({
        fulfillmentOrders: [],
        pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
        }
    })
    const [pageLoading, setPageLoading] = useState(true)

    useEffect( () => {
        async function fetchData() {
            const {data} = await Axios.get(`fulfillmentOrders/getFulfillmentOrders?assigned_location_id=${serviceLocationId}`,{
                baseURL: process.env.REACT_APP_BASE_URL,
                withCredentials: true,
            })
            return data
        }
        fetchData().then(res => {
            setFulfillments(res)
            setPageLoading(false)
        })
    }, [serviceLocationId])

    const rowMarkup = !pageLoading ? fulfillments.fulfillmentOrders.map(
        ({node}, index) => {
            const {id, order, requestStatus, status, lineItems, merchantRequests} = node
            const date = new Date(order.createdAt)
            const dayMonth = new Intl.DateTimeFormat('en-US', {month: "short", day: "numeric"}).format(date)
            // const year = new Intl.DateTimeFormat('en-US', {year: "numeric"}).format(date)
            const time = new Intl.DateTimeFormat('en-US', {hour: "numeric", minute: "numeric"}).format(date).toLowerCase()
            const totalItems = lineItems.edges.reduce((previousValue, currentValue) => previousValue + currentValue.node.totalQuantity, 0)

            let recipientFirstName = ""
            let recipientLastName = ""
            if (node.lineItems.edges[0]) {
                const customAttributes = lineItems.edges[0].node.lineItem.customAttributes
                for (let attribute of customAttributes) {
                    if (attribute.key === "Recipient's First Name")
                        recipientFirstName = attribute.value
                    else if (attribute.key === "Recipient's Last Name")
                        recipientLastName = attribute.value
                }
            } else {
                recipientFirstName = "N/A"
            }


            return (
                <IndexTable.Row id={id} key={id} position={index} onNavigation={() => {
                    navigate("/fulfillmentServices/assignedOrders/fulfillmentOrder", {state: {...node, serviceName: location.state.name}})
                }}>
                    <IndexTable.Cell>
                        <TextStyle variation={"strong"}><a data-primary-link={""}>{order.name}</a></TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{`${dayMonth} at ${time}`}</IndexTable.Cell>
                    <IndexTable.Cell>{`${order.customer.firstName} ${order.customer.lastName}`}</IndexTable.Cell>
                    <IndexTable.Cell>{ recipientFirstName === "" ? `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`
                        : `${recipientFirstName} ${recipientLastName}`}</IndexTable.Cell>
                    <IndexTable.Cell>{returnStatusRequestBadge(requestStatus, status)}</IndexTable.Cell>
                    <IndexTable.Cell>{returnStatusFulfillmentBadge(status, requestStatus)}</IndexTable.Cell>
                    <IndexTable.Cell>{totalItems === 1 ? `${totalItems} item` : `${totalItems} items`}</IndexTable.Cell>
                </IndexTable.Row>
            )
        }
    ) : null

    return (
        <PageWrapper currentPage={"Fulfillment Orders"}>
            <Page
                title={
                    <DisplayText size={"extraLarge"}>{location.state.name}</DisplayText>
                }
                divider={true}
                fullWidth={true}
                breadcrumbs={[{content: 'View Fulfillment Orders', onAction() {
                    navigate(-1)
                }}]}
            >
            {pageLoading ?
                <div style={{height: '100px'}}>
                    <Loading />
                </div>
                :
                <Stack vertical={true}>

                    <Stack vertical={true} spacing={"extraTight"}>
                        <Heading>Fulfillment Orders Requested</Heading>
                        <TextStyle variation={"subdued"}>You can view the status of all fulfillment orders here.
                            Select a fulfillment order to access more options on that particular order.</TextStyle>
                    </Stack>
                    <Card>
                        <IndexTable
                            resourceName={{singular: 'fulfillment order', plural: 'fulfillment orders'}}
                            itemCount={fulfillments.fulfillmentOrders.length}
                            headings={[
                                {title: "Order"},
                                {title: "Date"},
                                {title: "Purchased By"},
                                {title: "Deliver To"},
                                {title: "Request Status"},
                                {title: "Fulfillment Status"},
                                {title: "Items"},
                            ]}
                            selectable={false}
                            // emptyState={emptyStateMarkup}
                        >
                            {rowMarkup}
                        </IndexTable>
                        <Card.Section>
                            <Stack distribution={"center"}>
                                <Pagination
                                    hasPrevious={fulfillments.pageInfo.hasPreviousPage}
                                    onPrevious={async () => {
                                        setPageLoading(true)
                                        const cursor = fulfillments.fulfillmentOrders[0].cursor
                                        const {data} = await Axios.get(`fulfillmentOrders/getFulfillmentOrders?assigned_location_id=${serviceLocationId}&cursor=${cursor}&direction=previous`, {
                                            baseURL: process.env.REACT_APP_BASE_URL,
                                            withCredentials: true,
                                        })
                                        setFulfillments(data)
                                        setPageLoading(false)
                                    }}
                                    hasNext={fulfillments.pageInfo.hasNextPage}
                                    onNext={async () => {
                                        setPageLoading(true)
                                        const cursor = fulfillments.fulfillmentOrders[fulfillments.fulfillmentOrders.length - 1].cursor
                                        const {data} = await Axios.get(`fulfillmentOrders/getFulfillmentOrders?assigned_location_id=${serviceLocationId}&cursor=${cursor}&direction=next`, {
                                            baseURL: process.env.REACT_APP_BASE_URL,
                                            withCredentials: true,
                                        })
                                        setFulfillments(data)
                                        setPageLoading(false)
                                    }}
                                />
                            </Stack>
                        </Card.Section>
                    </Card>
                </Stack>
            }
            </Page>
        </PageWrapper>

    )
}