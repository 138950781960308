import {Stack, Thumbnail, TextContainer, TextStyle, TextField} from "@shopify/polaris";

export default function LineItemTrackingModalInfo({item, quantityToFulfill, handleQuantityToFulfillChange, maxQuantity, fulfillmentInfo}) {
    return (
        <Stack vertical={true}>
            <Stack distribution={"fill"}>
                <Thumbnail source={item.media.props.source} alt={item.name}/>
                <TextContainer>
                    <TextContainer>
                        <Stack vertical={true} spacing={"none"}>
                            <TextStyle variation={"strong"}>{item.name}</TextStyle>
                            <TextStyle>SKU: {item.sku}</TextStyle>
                            <TextStyle variation={"strong"}>Fulfillable Quantity: {item.unfulfilledQuantity}</TextStyle>
                        </Stack>
                    </TextContainer>
                    <Stack>
                        <TextContainer>
                            <Stack vertical={true} spacing={"none"}>
                                <TextStyle variation={"strong"}>Deliver to:</TextStyle>
                                <TextStyle>{item.recipientName !== "N/A N/A" ?
                                    `${item.recipientName}` :
                                    `${fulfillmentInfo.order.customer.firstName} ${fulfillmentInfo.order.customer.lastName}`}</TextStyle>
                                <TextStyle>{item.recipientAddress !== "N/A" ? `${item.recipientAddress}`:`${fulfillmentInfo.order.shippingAddress.address1} ${fulfillmentInfo.order.shippingAddress.address2}`}</TextStyle>
                                { item.recipientZipCode !== "N/A" ?
                                    <TextStyle>{item.recipientZipCode}</TextStyle> :
                                    <TextStyle>{fulfillmentInfo.order.shippingAddress.city} {fulfillmentInfo.order.shippingAddress.province} {fulfillmentInfo.order.shippingAddress.zip}
                                    </TextStyle>
                                }
                                <TextStyle>{item.recipientName === "N/A N/A" ?
                                    `${fulfillmentInfo.order.shippingAddress.country}`:`${item.recipientCountry}`}
                                </TextStyle>
                            </Stack>
                        </TextContainer>
                        <Stack.Item fill>
                            <TextField label={"Quantity to Fulfill"} autoComplete={"off"}
                                       type={"number"} align={"right"}
                                       placeholder={maxQuantity}
                                       value={quantityToFulfill} onChange={handleQuantityToFulfillChange}
                                       min={1} max={maxQuantity}
                                       helpText={`Must fulfill at least 1. Maximum ${maxQuantity}.`}
                            />
                        </Stack.Item>

                    </Stack>
                </TextContainer>
            </Stack>
        </Stack>
    )
}