import {Card, Stack, SkeletonPage, SkeletonDisplayText} from "@shopify/polaris";

export default function VSF_SKELETON() {
    return (
        <SkeletonPage fullWidth title>
            <Card>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack distribution={"fillEvenly"}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                    </Stack>
                </Card.Section>
            </Card>
        </SkeletonPage>
    )
}